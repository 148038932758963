import React from "react";
import PropTypes from "prop-types";
import Select from "./Select";

const PipelineVersions = props => {

  const { pipelineVersions, pipelineVersionId, setPipelineVersionId, nextflowVersions, nextflowVersion, setNextflowVersion } = props;

  const labelClass = "text-flow-blue-7 text-sm font-medium lg:text-base";

  const versionOptions = pipelineVersions.map(version => ({label: version.name, value: version.id}));
  const nextflowVersionOptions = nextflowVersions.map(version => ({label: version, value: version}));

  return (
    <div className="flex gap-4 text-sm pb-4 border-b border-flow-gray-3 mb-6 sm:gap-8 sm:pb-6 sm:mb-6">
      <div>
        <label className={labelClass}>Pipeline version</label>
        <Select
          options={versionOptions}
          value={pipelineVersionId}
          setValue={version => setPipelineVersionId(version)}
        />
      </div>
      <div>
        <label className={labelClass}>Nextflow version</label>
        <Select
          options={nextflowVersionOptions}
          value={nextflowVersion}
          setValue={setNextflowVersion}
        />
      </div>
    </div>
  );
};

PipelineVersions.propTypes = {
  pipelineVersions: PropTypes.array.isRequired,
  pipelineVersionId: PropTypes.string.isRequired,
  setPipelineVersionId: PropTypes.func.isRequired,
  nextflowVersions: PropTypes.array.isRequired,
  nextflowVersion: PropTypes.string,
  setNextflowVersion: PropTypes.func.isRequired,
};

export default PipelineVersions;