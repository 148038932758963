import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";
import { useLazyFetch } from "../hooks";
import Button from "./Button";
import { UserContext } from "../contexts";

const Notification = props => {

  const { notification } = props;

  const [resolved, setResolved] = useState(false);

  const minorRowClass = "text-xs text-flow-blue-1";
  const mainRowClass = "block font-medium";
  const buttonClass = "text-xs py-0.5 px-2";

  const [user, setUser] = useContext(UserContext);

  const now = moment();
  const created = moment(notification.created * 1000);
  let time = "";
  if (now.diff(created, "days") >= 1) {
    time = created.format("MMM D");
  } else {
    time = created.format("h:mm A");
  }

  const updateUserWithGroup = group => {
    const newUser = {...user};
    if (!newUser.memberships.find(m => m.group.slug === group.slug)) {
      newUser.memberships.push(group);
      setUser(newUser);
    }
    setResolved(true);
  }

  const [{ loading: acceptingInvitation}, acceptInvitation] = useLazyFetch(
    `/groups/${notification.group?.slug}/invite/accept`,
    {method: "POST", onCompleted: updateUserWithGroup}
  )

  const [{ loading: decliningInvitation}, declineInvitation] = useLazyFetch(
    `/groups/${notification.group?.slug}/invite/decline`,
    {method: "POST", onCompleted: () => setResolved(true)}
  )

  return (
    <div className={` h-fit py-1.5 px-1.5 bg-[#F6F6F6]`}>
      <div className={`flex items-stretch gap-2 px-1.5 py-1.5 rounded ${notification.has_read ? "" : "bg-[#EEEEF0]"}`}>
        {!notification.has_read && <div className="w-1 bg-flow-blue-7" />}
        <div className="flex-grow">
          {notification.category === 1 && (
            <>
              <div className={minorRowClass}>
                <Link to={`/users/${notification.user.username}/`}>{notification.user.name}</Link> has invited you to join
              </div>
              <Link className={mainRowClass} to={`/groups/@${notification.group.slug}/`}>{notification.group.name}</Link>
              {!notification.resolved && !resolved && (
                <div className="flex gap-1 mt-1">
                  <Button
                    className={`btn-primary ${buttonClass}`}
                    loading={acceptingInvitation}
                    onClick={acceptInvitation}
                  >
                    Accept
                  </Button>
                  <Button
                    className={`btn-secondary ${buttonClass}`}
                    loading={decliningInvitation}
                    onClick={declineInvitation}
                  >
                    Decline
                  </Button>
                </div>
              )}
            </>
          )}
          {notification.category === 2 && (
            <>
              <Link className={mainRowClass} to={`/users/${notification.user.username}/`}>{notification.user.name}</Link>
              <div className={minorRowClass}>
                accepted your invitation to join <Link to={`/groups/@${notification.group.slug}/`}>{notification.group.name}</Link> 
              </div>
            </>
          )}
          {notification.category === 3 && (
            <>
              <Link className={mainRowClass} to={`/users/${notification.user.username}/`}>{notification.user.name}</Link>
              <div className={minorRowClass}>
                declined your invitation to join <Link to={`/groups/@${notification.group.slug}/`}>{notification.group.name}</Link> 
              </div>
            </>
          )}
          {notification.category === 4 && (
            <>
              <Link className={minorRowClass} to={`/users/${notification.user.username}/`}>{notification.user.name} has made you an admin of</Link>
              <div className={mainRowClass}>
                <Link to={`/groups/@${notification.group.slug}/`}>{notification.group.name}</Link> 
              </div>
            </>
          )}
          {notification.category === 5 && (
            <>
              <Link className={minorRowClass} to={`/users/${notification.user.username}/`}>{notification.user.name} has removed you as an admin of</Link>
              <div className={mainRowClass}>
                <Link to={`/groups/@${notification.group.slug}/`}>{notification.group.name}</Link> 
              </div>
            </>
          )}
          {notification.category === 6 && (
            <>
              <Link className={minorRowClass} to={`/users/${notification.user.username}/`}>{notification.user.name} has removed you from </Link>
              <div className={mainRowClass}>
                <Link to={`/groups/@${notification.group.slug}/`}>{notification.group.name}</Link> 
              </div>
            </>
          )}
          {notification.category === 7 && (
            <>
              <Link className={minorRowClass} to={`/users/${notification.user.username}/`}>{notification.user.name} has removed the group </Link>
              <div className={mainRowClass}>
                {notification.text}
              </div>
            </>
          )}
          {notification.category === 8 && notification.data && (
            <>
              <div className={minorRowClass}><Link to={`/users/${notification.user.username}/`}>{notification.user.name}</Link> has transferred data to <Link to={`/groups/@${notification.group.slug}/`}>{notification.group.name}</Link></div>
              <Link to={`/data/${notification.data.id}/`} className={mainRowClass}>
                {notification.data.filename}
              </Link>
            </>
          )}
          {notification.category === 8 && notification.execution && (
            <>
              <div className={minorRowClass}><Link to={`/users/${notification.user.username}/`}>{notification.user.name}</Link> has transferred a pipeline execution to <Link to={`/groups/@${notification.group.slug}/`}>{notification.group.name}</Link></div>
              <Link to={`/executions/${notification.execution.id}/`} className={mainRowClass}>
                {notification.execution.pipeline_name} execution
              </Link>
            </>
          )}
          {notification.category === 8 && notification.sample && (
            <>
              <div className={minorRowClass}><Link to={`/users/${notification.user.username}/`}>{notification.user.name}</Link> has transferred a sample to <Link to={`/groups/@${notification.group.slug}/`}>{notification.group.name}</Link></div>
              <Link to={`/samples/${notification.sample.id}/`} className={mainRowClass}>
                {notification.sample.name}
              </Link>
            </>
          )}
          {notification.category === 8 && notification.project && (
            <>
              <div className={minorRowClass}><Link to={`/users/${notification.user.username}/`}>{notification.user.name}</Link> has transferred a project to <Link to={`/groups/@${notification.group.slug}/`}>{notification.group.name}</Link></div>
              <Link to={`/projects/${notification.project.id}/`} className={mainRowClass}>
                {notification.project.name}
              </Link>
            </>
          )}

          {notification.category === 9 && notification.data && (
            <>
              <div className={minorRowClass}><Link to={`/users/${notification.user.username}/`}>{notification.user.name}</Link> from <Link to={`/groups/@${notification.group.slug}/`}>{notification.group.name}</Link> has transferred data to you</div>
              <Link to={`/data/${notification.data.id}/`} className={mainRowClass}>
                {notification.data.filename}
              </Link>
            </>
          )}
          {notification.category === 9 && notification.execution && (
            <>
              <div className={minorRowClass}><Link to={`/users/${notification.user.username}/`}>{notification.user.name}</Link> from <Link to={`/groups/@${notification.group.slug}/`}>{notification.group.name}</Link> has transferred a pipeline execution to you</div>
              <Link to={`/executions/${notification.execution.id}/`} className={mainRowClass}>
                {notification.execution.pipeline_name} execution
              </Link>
            </>
          )}
          {notification.category === 9 && notification.sample && (
            <>
              <div className={minorRowClass}><Link to={`/users/${notification.user.username}/`}>{notification.user.name}</Link> from <Link to={`/groups/@${notification.group.slug}/`}>{notification.group.name}</Link> has transferred a sample to you</div>
              <Link to={`/samples/${notification.sample.id}/`} className={mainRowClass}>
                {notification.sample.name}
              </Link>
            </>
          )}
          {notification.category === 9 && notification.project && (
            <>
              <div className={minorRowClass}><Link to={`/users/${notification.user.username}/`}>{notification.user.name}</Link> from <Link to={`/groups/@${notification.group.slug}/`}>{notification.group.name}</Link> has transferred a project to you</div>
              <Link to={`/projects/${notification.project.id}/`} className={mainRowClass}>
                {notification.project.name}
              </Link>
            </>
          )}
        </div>
        <time className="text-gray-400 text-2xs">{time}</time>
      </div>
    </div>
  );
};

Notification.propTypes = {
  notification: PropTypes.object.isRequired
};

export default Notification;