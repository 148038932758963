import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as GithubIcon } from "../images/github.svg";


const PipelineRepoLink = props => {

  const { pipeline } = props;

  const url = pipeline.repo_original_url || pipeline.repo_url;
  const path = url.split("://")[1].split("/").slice(1).join("/");

  return (
    <a href={pipeline.repo_original_url || pipeline.repo_url} className="text-flow-blue-7 text-sm flex items-center gap-1.5 md:text-base">
      <GithubIcon className="inline-block w-4 h-auto mt-0.5 md:w-5 md:mt-0" />
      {path}
    </a>
  );
};

PipelineRepoLink.propTypes = {
  pipeline: PropTypes.object.isRequired,
};

export default PipelineRepoLink;