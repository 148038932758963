import React, { useState } from "react";
import PropTypes from "prop-types";
import DeletePipelineSubcategory from "./DeletePipelineSubcategory";
import PipelineSubcategoryForm from "./PipelineSubcategoryForm";
import { useMutation } from "@apollo/client";
import { PIPELINE_ADMIN } from "../queries";
import { MOVE_SUBCATEGORY_UP, MOVE_SUBCATEGORY_DOWN } from "../mutations";
import { ReactComponent as DownIcon } from "../images/down.svg";

const PipelineSubcategoryAdmin = props => {

  const { subcategory, categoryId, isFirst, isLast } = props;

  const [editing, setEditing] = useState(false);

  const [moveUp, moveUpMutation] = useMutation(MOVE_SUBCATEGORY_UP, {
    variables: { id: subcategory.id },
    refetchQueries: [{ query: PIPELINE_ADMIN }],
  });

  const [moveDown, moveDownMutation] = useMutation(MOVE_SUBCATEGORY_DOWN, {
    variables: { id: subcategory.id },
    refetchQueries: [{ query: PIPELINE_ADMIN }],
  });

  const loading = moveUpMutation.loading || moveDownMutation.loading;

  if (editing) return <PipelineSubcategoryForm subcategory={subcategory} setShow={setEditing} categoryId={categoryId} />;

  return (
    <div className={`${props.className || ""} ${loading ? "opacity-50 pointer-events-none" : ""}`}>
      <div className="text-base">
        {subcategory.name}
        <button className="text-2xs mx-1 relative bottom-2 text-flow-blue-7" onClick={() => setEditing(true)}>
          Edit
        </button>
        {subcategory.pipelineCount === 0 && <DeletePipelineSubcategory subcategory={subcategory}  />}
        {!isLast && (
          <DownIcon className="cursor-pointer relative bottom-2 h-2.5 w-auto fill-flow-blue-7 inline" onClick={moveDown} />
        )}
        {!isFirst && (
          <DownIcon className="cursor-pointer relative bottom-2 h-2.5 w-auto rotate-180 fill-flow-blue-7 inline" onClick={moveUp} />
        )}
      </div>
      <div className="text-xs max-w-xl">{subcategory.description}</div>
    </div>
  );
};

PipelineSubcategoryAdmin.propTypes = {
  subcategory: PropTypes.object.isRequired,
  categoryId: PropTypes.string.isRequired,
  isFirst: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
};

export default PipelineSubcategoryAdmin;