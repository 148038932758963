import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import DataContent from "../components/DataContent";
import DataSource from "../components/DataSource";
import ExecutionsTable2 from "../components/ExecutionsTable2";
import DataType from "../components/DataType";
import DownloadButton from "../components/DownloadButton";
import Tabs from "../components/Tabs";
import { useDocumentTitle, useLazyFetch, useFetch } from "../hooks";
import Base from "./Base";
import ObjectTitle from "../components/ObjectTitle";
import ObjectAttributes from "../components/ObjectAttributes";
import { ClipLoader } from "react-spinners";

const DataPage = () => {

  const { id } = useParams();

  const [tab, setTab] = useState("Content");
  const [downstreamExecutionsPage, setDownstreamExecutionsPage] = useState(1);
  const [downstreamExecutionsCount, setDownstreamExecutionsCount] = useState(null);
  const [downstreamExecutions, setDownstreamExecutions] = useState(null);
  const [downstreamExecutionsFilter, setDownstreamExecutionsFilter] = useState(null);
  const [hasDownstreamExecutions, setHasDownstreamExecutions] = useState(null);
  const pageSize = 20;

  const { loading, data, status } = useFetch(`/data/${id}`);

  useDocumentTitle(data ? `${data.filename} - Flow` : "Flow");

  const { loading: downstreamExecutionsLoading } = useFetch(`/data/${id}/downstream`, {
    params: {page: downstreamExecutionsPage, count: pageSize, filter: downstreamExecutionsFilter},
    onCompleted: data => {
      setDownstreamExecutionsCount(data.count);
      setDownstreamExecutions(data.executions);
      if (hasDownstreamExecutions === null) setHasDownstreamExecutions(!!data.count);
      const maxPossiblePage = Math.ceil(data.count / pageSize);
      if (downstreamExecutionsPage > maxPossiblePage) setDownstreamExecutionsPage(maxPossiblePage || 1);
    }
  })

  const [,postBulkDelete] = useLazyFetch(`/data/${id}/downstream`, {
    params: {page: downstreamExecutionsPage, count: pageSize, filter: downstreamExecutionsFilter},
    onCompleted: data => {
      setDownstreamExecutionsCount(data.count);
      setDownstreamExecutions(data.executions);
    }
  })

  if (loading) return <Base loading={true} />

  if (status === 404) return <Base notFound={true} />

  return (
    <Base>
      <ObjectTitle 
        isPrivate={data.private}
        title={data.filename} 
        type="data"
        editUrl={data.can_edit && `/data/${id}/edit`}
        corner={data.category !== 1 ? <DataType data={data} /> : null}
      />
      <ObjectAttributes object={data} />
      <DataSource data={data} className="my-4" />
      <DownloadButton className="mb-6 text-sm px-3" data={data} />
      <Tabs labels={["Content", "Usage"]} selected={tab} setSelected={setTab} />
      {tab === "Content" && <DataContent data={data} />}
      {tab === "Usage" && (
        <>
          {!downstreamExecutionsLoading && !hasDownstreamExecutions && (
            <div className="text-sm mb-6 info sm:mb-8">
              This data has not been used in a pipeline run yet.
            </div>
          )}
          {downstreamExecutionsLoading && !hasDownstreamExecutions && (
            <div className="w-full h-48 flex justify-center items-center">
              <ClipLoader size={100} />
            </div>
          )}
          {hasDownstreamExecutions && (
            <ExecutionsTable2
              executions={downstreamExecutions}
              page={downstreamExecutionsPage}
              pageSize={pageSize}
              setPage={setDownstreamExecutionsPage}
              totalCount={downstreamExecutionsCount}
              loading={downstreamExecutionsLoading}
              onFilter={s => setDownstreamExecutionsFilter(s || null)}
              onBulkDelete={postBulkDelete}
              noMessage="No downstream executions matches that term." 
            />
          )}
        </>
      )}
    </Base>
  )
};

DataPage.propTypes = {

};

export default DataPage;