import { useState } from "react";
import PropTypes from "prop-types";
import SampleTypeDeletionModal from "./SampleTypeDeletionModal";
import MetadataAttributeDeletionModal from "./MetadataAttributeDeletionModal";
import SampleTypeEditModal from "./SampleTypeEditModal";
import Button from "./Button";
import { useLazyFetch } from "../hooks";
import MetadataAttributeEditModal from "./MetadataAttributeEditModal";

const SampleTypeAdmin = props => {

  const { sampleType, setSampleType, sampleTypes, setSampleTypes } = props;

  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [attributeToEdit, setAttributeToEdit] = useState(null);
  const [attributeToDelete, setAttributeToDelete] = useState(null);
  const [newMetadataId, setNewMetadataId] = useState("");
  const [newMetadataName, setNewMetadataName] = useState("");
  const [newMetadataDescription, setNewMetadataDescription] = useState("");
  const [newMetadataRequired, setNewMetadataRequired] = useState(false);
  const [newMetadataOptions, setNewMetadataOptions] = useState("");
  const [errorFields, setErrorFields] = useState([]);

  const [{ loading: metadataCreationLoading }, createMetadata] = useLazyFetch("/samples/metadata/create", {
    method: "POST",
    params: {
        id: newMetadataId,
        name: newMetadataName,
        description: newMetadataDescription,
        sample_type: sampleType.id,
        required: newMetadataRequired,
        options: newMetadataOptions ? newMetadataOptions.split(",").map(o => o.trim()) : []
    },
    onCompleted: data => {
      setErrorFields([]);
      setSampleTypes(sampleTypes.map(t => t.id === sampleType.id ? data : t));
      setNewMetadataId("");
      setNewMetadataName("");
      setNewMetadataDescription("");
      setNewMetadataRequired(false);
      setNewMetadataOptions("");
    },
    onError: error => {
      setErrorFields(Object.keys(error.error || {}));
    }
  });

  const canCreateMetadata = newMetadataId && newMetadataName && newMetadataDescription;

  const inputClass = "text-xs";

  return (
    <div className="border-b border-gray-300 pb-4">
      <div className="flex items-center gap-1 mb-1">
        <div className="text-xs border rounded px-1.5 py-0.5 bg-gray-50 text-gray-700 font-medium">{sampleType.id}</div>
        <div>{sampleType.name}</div>
        <button
          className="text-blue-600 text-xs relative bottom-1.5 cursor-pointer"
          onClick={() => setShowEditModal(true)}
        >
          Edit
        </button>
        <button
          className="text-red-600 text-xs relative bottom-1.5 cursor-pointer"
          onClick={() => setShowDeleteModal(true)}
        >
          Delete
        </button>
      </div>
      <div className="text-sm">{sampleType.description}</div>

      <div className="mt-3 flex flex-col gap-3 ml-8">
        {sampleType.metadata.map(meta => (
          <div key={meta.id} className="">
            <div className="flex items-center gap-1 mb-0.5">
              <div className="text-2xs border rounded px-1 py-px bg-gray-50 text-gray-700 font-medium">{meta.id}</div>
              <div className="text-sm">{meta.name} ({meta.required ? "Required" : "Optional"})</div>
              <button
                className="text-blue-600 text-2xs relative bottom-1.5 cursor-pointer"
                onClick={() => setAttributeToEdit(meta)}
              >
                Edit
              </button>
              <button
                className="text-red-600 text-2xs relative bottom-1.5 cursor-pointer"
                onClick={() => setAttributeToDelete(meta)}
              >
                Delete
              </button>
            </div>
            <div className="text-sm">{meta.description}</div>
            {meta.options.length > 0 && (
              <div className="text-2xs font-mono">{meta.options.join(",")}</div>
            )}
          </div>
        ))}

        <div>
          <div className="font-medium mb-2 text-sm">Create a new metadata attribute</div>
          <div className="flex gap-1.5">
            <input
              placeholder="ID"
              className={`${inputClass} ${errorFields.includes("id") ? "text-red-600 bg-red-200" : "text-gray-700 pl-0"} text-2xs w-24 pr-1.5 py-0 border-r rounded-none outline-none`}
              value={newMetadataId}
              onChange={e => setNewMetadataId(e.target.value)}
            />
            <input
              className={`${inputClass} ${errorFields.includes("name") ? "text-red-600 bg-red-200" : "text-gray-700 pl-0"} text-2xs w-48 py-0 rounded-none `}
              placeholder="Name"
              value={newMetadataName}
              onChange={e => setNewMetadataName(e.target.value)}
            />
          </div>
          <input
            className={`${inputClass} ${errorFields.includes("description") ? "text-red-600 bg-red-200" : "text-gray-700 pl-0"} block text-xs mt-1.5 pt-1.5 pb-0.5 rounded-none pl-0 border-t w-72`}
            placeholder="Description"
            value={newMetadataDescription}
            onChange={e => setNewMetadataDescription(e.target.value)}
          />
          <input
            className={`${inputClass} ${errorFields.includes("options") ? "text-red-600 bg-red-200" : "text-gray-700 pl-0"} text-xs mt-1.5 py-1.5 rounded-none pl-0 border-t w-72 font-mono placeholder:font-sans`}
            placeholder="Options (comma separated)"
            value={newMetadataOptions}
            onChange={e => setNewMetadataOptions(e.target.value)}
          />
          <div className="flex items-center gap-1">
            <label className="text-xs text-gray-700 cursor-pointer" htmlFor={`required-${sampleType.id}`}>Required</label>
            <input
              id={`required-${sampleType.id}`}
              type="checkbox"
              className="cursor-pointer"
              checked={newMetadataRequired}
              onChange={e => setNewMetadataRequired(e.target.checked)}
            />
          </div>

          <Button
            className={`block w-fit btn-primary py-0.5 px-2 mt-1.5 text-xs ${canCreateMetadata ? "" : "opacity-40 pointer-events-none"}`}
            loading={metadataCreationLoading}
            onClick={createMetadata}
          >
            Create
          </Button>
        </div>
      </div>
      {showEditModal && (
        <SampleTypeEditModal
          sampleType={sampleType}
          sampleTypes={sampleTypes}
          setSampleTypes={setSampleTypes}
          setShow={setShowEditModal}
        />
      )}
      {showDeleteModal && (
        <SampleTypeDeletionModal
          sampleType={sampleType}
          sampleTypes={sampleTypes}
          setSampleTypes={setSampleTypes}
          setShow={setShowDeleteModal}
        />
      )}
      {attributeToEdit && (
        <MetadataAttributeEditModal
          attribute={attributeToEdit}
          sampleTypes={sampleTypes}
          setSampleTypes={setSampleTypes}
          setShow={setAttributeToEdit}
        />
      )}
      {attributeToDelete && (
        <MetadataAttributeDeletionModal
          metadataAttribute={attributeToDelete}
          setSampleType={setSampleType}
          setShow={setAttributeToDelete}
        />
      )}
    </div>
  );
};

SampleTypeAdmin.propTypes = {
  sampleType: PropTypes.object.isRequired,
  setSampleType: PropTypes.func.isRequired,
  sampleTypes: PropTypes.array.isRequired,
  setSampleTypes: PropTypes.func.isRequired
};

export default SampleTypeAdmin;