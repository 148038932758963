import { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useLazyFetch } from "../hooks";
import { ReactComponent as Logo } from "../images/logo.svg";
import ResetPasswordModal from "./ResetPasswordModal";
import { TOKEN } from "../queries";
import { client } from "../api";
import { UserContext, TokenContext } from "../contexts";
import Button from "./Button";

const AuthForm = props => {

  const { isLogin, isReset } = props;

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showReset, setShowReset] = useState(false);
  const [errors, setErrors] = useState(null);
  const [,setUser] = useContext(UserContext);
  const tokenRef = useContext(TokenContext);
  const [searchParams,] = useSearchParams();
  const navigate = useNavigate();

  const [{ loading: signupLoading}, signup] = useLazyFetch("/signup", {
    method: "POST",
    params: {username, email, name, password},
    onCompleted: data => {
      setUser(data.user);
      tokenRef.current = data.token;
      client.cache.writeQuery({
        query: TOKEN, data: {accessToken: data.token}
      });
      navigate("/");
    },
    onError: error => setErrors(error.error)
  });

  const [{ loading: loginLoading}, login] = useLazyFetch("/login", {
    method: "POST",
    params: {username, password},
    onCompleted: data => {
      setUser(data.user);
      tokenRef.current = data.token;
      client.cache.writeQuery({
        query: TOKEN, data: {accessToken: data.token}
      });
      navigate("/");
    },
    onError: () => setErrors({__all__: ["Invalid username or password."]})
  });

  const [{ loading: resetPasswordLoading}, resetPassword] = useLazyFetch("/password-reset", {
    method: "POST",
    onCompleted: () => navigate("/login/"),
    onError: error => setErrors(error.error)
  });

  const formSubmit = e => {
    e.preventDefault();
    setErrors(null);
    if (isLogin) {
      login();
    } else if (isReset) {
      const token = searchParams.get("token");
      if (password !== confirmPassword) {
        setErrors({token: ["Passwords do not match."]});
      } else {
        resetPassword({params: {token, password}});
      }
    } else {
      signup();
    }
  }

  const blockClass = "flex gap-2 mb-6 items-end w-full sm:mb-10";
  const labelClass = "w-14 block pb-4 text-right text-xs text-white flex-shrink-0 sm:text-sm sm:w-16";
  const inputBlockClass = "w-full flex-grow min-w-0 flex flex-col gap-0.5";
  const inputClass = "border-2 rounded w-full py-2.5 px-2.5 text-flow-blue-2 font-medium text-base sm:text-lg";
  const errorInputClass = "border-red-500 border-2 text-red-700";
  const errorTextClass = "left-16 text-red-100 text-xs -mt-3 sm:-mt-6";
  const validation = errors || {};
  const loading = loginLoading || signupLoading || resetPasswordLoading;

  return (
    <>
      <form
        className="mx-auto my-auto w-full h-full bg-flow-blue-6 px-8 py-3 sm:w-full sm:max-w-md sm:h-auto sm:rounded-lg sm:shadow-lg"
        onSubmit={formSubmit}
      >
        <Link to="/" className="mx-auto block w-fit my-8 mb-12"><Logo fill="white" className="h-12 w-auto" /></Link>

        {isReset && (
          <div className="text-white ml-16 mb-10 sm:ml-18">
            <div className="text-2xl mb-2">Create new password</div>
            <div className="text-sm">This will reset whatever your previous password was.</div>
          </div>
        )}
        {!isReset && (
          <div className={blockClass}>
            <label className={labelClass} htmlFor="username">username</label>
            <div className={inputBlockClass}>
              {validation.__all__ && (<div className={errorTextClass}>{validation.__all__[0]}</div>)}
              {validation.username && (<div className={errorTextClass}>{validation.username[0]}</div>)}
              <input
                id="username"
                type="text"
                value={username}
                onChange={e => setUsername(e.target.value)}
                className={`${inputClass} ${errors?.message || validation.__all__ || validation.username ? errorInputClass : "border-white"}`}
                autoComplete="username"
                autoCapitalize="none"
                required
              />
            </div>
          </div>
        )}

        {!isLogin && !isReset && (
          <div className={blockClass}>
            <label className={labelClass} htmlFor="name">full name</label>
            <div className={inputBlockClass}>
              {validation.name && (<div className={errorTextClass}>{validation.name[0]}</div>)}
              <input
                id="name"
                type="text"
                value={name}
                onChange={e => setName(e.target.value)}
                className={`${inputClass} ${validation.name ? errorInputClass : "border-white"}`}
                autoComplete="name"
                autoCapitalize="none"
                required
              />
            </div>
          </div>
        )}

        {!isLogin && !isReset && (
          <div className={blockClass}>
            <label className={labelClass} htmlFor="email">email</label>
            <div className={inputBlockClass}>
              {validation.email && (<div className={errorTextClass}>{validation.email[0]}</div>)}
              <input
                id="email"
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                className={`${inputClass} ${validation.email ? errorInputClass : "border-white"}`}
                autoComplete="email"
                autoCapitalize="none"
                required
              />
            </div>
          </div>
        )}

        <div className={blockClass}>
          <label className={labelClass} htmlFor="password">password</label>
          <div className={inputBlockClass}>
            {validation.password && (<div className={errorTextClass}>{validation.password[0]}</div>)}
            {validation.token && (<div className={errorTextClass}>{validation.token[0]}</div>)}
            <input
              id="password"
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              className={`${inputClass} ${errors?.message || validation.__all__ || validation.password || validation.token ? errorInputClass : "border-white"}`}
              autoComplete={isLogin ? "current-password" : "new-password"}
              autoCapitalize="none"
              required
            />
          </div>
        </div>

        {isReset && (
          <div className={blockClass}>
            <label className={labelClass} htmlFor="confirm">confirm</label>
            <div className={inputBlockClass}>
              <input
                id="confirm"
                type="password"
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
                className={`${inputClass} ${validation.token ? errorInputClass : "border-white"}`}
                autoComplete="new-password"
                autoCapitalize="none"
                required
              />
            </div>
          </div>
        )}

        {!isReset && (
          <div className="text-white text-xs ml-16 sm:ml-18 sm:text-sm">
            {!isLogin && <Link to="/terms/">Terms and Conditions</Link>}
            {isLogin && (
              <div
                className="cursor-pointer hover:underline w-fit"
                onClick={() => setShowReset(true)}
              >Forgot your password?</div>
            )}
          </div>
        )}

        <Button type="submit" className="text-white ml-16 btn-primary w-28 mt-8 py-2 text-sm sm:ml-18 sm:text-base sm:w-36" loading={loading}>
          {isLogin ? "Log In" : isReset ? "Reset" : "Sign Up"}
        </Button>

        <div className="flex justify-end text-white mt-6 mb-2">
          {isLogin && !isReset && <Link to="/signup/">Sign Up</Link>}
          {!isLogin && !isReset && <Link to="/login/">Log In</Link>}
        </div>

      </form>
      {showReset && <ResetPasswordModal setShowModal={setShowReset} />}
    </>
  );
};

AuthForm.propTypes = {
  isLogin: PropTypes.bool,
  isReset: PropTypes.bool,
};

export default AuthForm;