import { useState } from "react";
import PropTypes from "prop-types";
import { useLazyFetch } from "../hooks";

const FilesetOrganismEditor = props => {

  const { fileset, setFileset, organisms } = props;

  const [displayOrganism, setDisplayOrganism] = useState("");

  const [{ loading }, updateFileset] = useLazyFetch(`/filesets/${fileset.id}/edit`, {
    method: "POST",
    onCompleted: fileset => {
      setFileset(fileset);
      setDisplayOrganism("");
    }
  });

  const onOrganismChange = e => {
    setDisplayOrganism(e.target.value || null);
    updateFileset({params: {organism: e.target.value || null}})
  }

  return (
    <div className={`${props.className || ""}`}>
      <div className="edit-heading">Organism</div>
      <select
        value={displayOrganism === "" ? (fileset.organism?.id || "") : displayOrganism}
        onChange={onOrganismChange}
        className={`border border-flow-gray-5 outline-none h-9 rounded cursor-pointer mb-1.5 py-1.5 text-flow-blue-2 font-medium px-3 text-sm md:text-base w-36 max-w-full bg-flow-gray-1 placeholder-text-[#777F9B] ${loading ? "opacity-40 pointer-events-none" : ""}`}
      >
        <option value="">No organism</option>
        {organisms.map(o => (
          <option key={o.id} value={o.id}>{o.name}</option>
        ))}
      </select>
    </div>
  );
};

FilesetOrganismEditor.propTypes = {
  fileset: PropTypes.object.isRequired,
  setFileset: PropTypes.func.isRequired,
  organisms: PropTypes.array.isRequired,
};

export default FilesetOrganismEditor;