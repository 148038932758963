import PropTypes from "prop-types";

const AnnotationSheetLinks = props => {

  const { sampleTypes, file } = props;

  const linkClass = "text-flow-blue-7 w-fit";

  const url = process.env.REACT_APP_DATA.replace("/data", "");

  const categories = [
    ...sampleTypes.map(type => [type.id, type.name]),
    ["generic", "Generic"]
  ]

  return (
    <div className={props.className || ""}>
      <div className="text-sm font-medium mb-1">Annotation sheet templates:</div>
      <div className="grid text-xs gap-0.5 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2">
        {categories.map(([name, displayName]) => (
          <a
            href={`${url}/annotation/${name}${file ? "?file=true" : ""}`}
            className={linkClass}
            key={name}
          >
            {displayName} Annotation Sheet
          </a>
        ))}
      </div>
    </div>
  );
};

AnnotationSheetLinks.propTypes = {
  sampleTypes: PropTypes.array.isRequired,
  file: PropTypes.bool,
};

export default AnnotationSheetLinks;