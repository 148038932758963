import React from "react";
import PropTypes from "prop-types";
import { ClipLoader } from "react-spinners";
import moment from "moment";

const LatestNews = props => {

  const { headingClass, news } = props;

  const categoryClass = "text-white rounded-full px-3 py-1 w-18 text-center text-xs";

  return (
    <div className="text-[#505B60] border-b border-[#E0E0E0] pb-8 pt-6 max-w-xl lg:pt-0">
      <div className={headingClass}>Latest Updates</div>
      <div className="flex flex-col gap-6 sm:gap-3">
        {news === null && <ClipLoader size={20} />}
        {news && news.length === 0 && <div className="info text-sm">No updates.</div>}
        {news && news.map(item => (
          <div key={item.id} className="flex items-center justify-between text-sm">
            <div className="flex gap-2 flex-col sm:flex-row sm:items-center">
              <div className="flex gap-2 items-center">
                {item.category === 1 && <div className={`${categoryClass} bg-flow-purple-4`}>Pipelines</div>}
                {item.category === 2 && <div className={`${categoryClass} bg-flow-green-2`}>Features</div>}
                {item.category === 3 && <div className={`${categoryClass} bg-flow-blue-3`}>News</div>}
                <div className="font-medium sm:hidden">{moment(item.date).format("D MMM YYYY")}</div>
              </div>
              <div className="text-xs md:text-sm">{item.headline}</div>

            </div>
            <div className="font-medium hidden whitespace-nowrap sm:block">{moment(item.date).format("D MMM YYYY")}</div>
          </div>
        ))}
      </div>
      
    </div>
  );
};

LatestNews.propTypes = {
  headingClass: PropTypes.string,
  news: PropTypes.array,
};

export default LatestNews;