import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import Input from "../components/Input";
import { useDocumentTitle, useLazyFetch } from "../hooks";
import Base from "./Base";

const NewProjectPage = () => {

  useDocumentTitle("New Project - Flow");

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState(null);
  const navigate = useNavigate();

  const [{ loading }, createProject] = useLazyFetch("/projects/new", {
    method: "POST",
    params: {name, description},
    onCompleted: project => {
      navigate(`/projects/${project.id}/`);
    },
    onError: error => setErrors(error.error)
  })

  const onSubmit = e => {
    e.preventDefault();
    createProject({variables: {name, description}});
  }

  const validation = errors || {};
  const cellClass = "flex-grow relative";
  const labelClass = "text-flow-gray-9 font-medium text-sm block mb-1.5";
  const inputClass = "block bg-flow-gray-2 text-flow-blue-7 rounded text-base px-3 py-1.5 w-full sm:text-lg";

  return (
    <Base>
      <h1>New Project</h1>
      <div className="text-base text-flow-gray-9 max-w-2xl mb-10">
        Projects are the top-level container for data in Flow. They are used to
        group related samples and other data which together contribute to a single
        overall research question. They may be associated with a particular
        published paper, and you can decide whether to make it private or public.
      </div>
      <form onSubmit={onSubmit}>
        <div className="flex gap-x-10 flex-wrap items-start gap-y-5 mb-5 max-w-2xl sm:gap-y-6 sm:mb-6">
          <div className={cellClass}>
            <label htmlFor="name" className={labelClass}>Project Name</label>
            <Input
              id="name"
              value={name}
              className={`${inputClass} text-base font-medium sm:text-lg`}
              onChange={e => setName(e.target.value)}
              required
              errors={validation.name}
            />
          </div>
        </div>
        <div className="w-full max-w-2xl">
          <label htmlFor="description" className={labelClass}>Description</label>
          <Input
            id="description"
            value={description}
            className={`${inputClass} resize-none h-36 text-sm sm:text-base`}
            onChange={e => setDescription(e.target.value)}
            required
            errors={validation.description}
            textarea={true}
          />
        </div>
        
        <Button className="btn-primary mt-8" type="submit" loading={loading}>
          Create Project
        </Button>
      </form>
    </Base>
  );
};

NewProjectPage.propTypes = {
  
};

export default NewProjectPage;