import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import UserImage from "./UserImage";

const UserGrid = props => {

  const { users, label } = props;

  return (
    <div className="py-8 border-t border-[#F2F2F2] xl:py-10">
      <div className="text-flow-gray-9 font-medium text-lg mb-2 sm:text-xl sm:mb-3">{label} ({users.length})</div>
      <div className="flex flex-col gap-x-12 gap-y-3 flex-wrap sm:gap-y-6 sm:flex-row xl:gap-x-16 xl:gap-y-6 xl:pt-2">
        {users.map(user => (
          <Link className="flex items-center gap-3 hover:no-underline" key={user.username} to={`/users/${user.username}/`}>
            <UserImage user={user} className="w-10 h-10 sm:w-16 sm:h-16 xl:w-20 xl:h-20" />
            <div className="text-[#4A4A4A] font-medium sm:w-40 xl:text-lg xl:w-48">{user.name}</div>
          </Link>
        ))}
      </div>
    </div>
  );
};

UserGrid.propTypes = {
  users: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
};

export default UserGrid;