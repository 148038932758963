import { useState } from "react";
import { useParams } from "react-router-dom";
import Fileset from "../components/Fileset";
import { useDocumentTitle, useFetch } from "../hooks";
import Base from "./Base";
import Tabs from "../components/Tabs";
import ObjectTitle from "../components/ObjectTitle";
import ObjectAttributes from "../components/ObjectAttributes";
import SampleTable2 from "../components/SamplesTable2";
import { ClipLoader } from "react-spinners";

const OrganismPage = () => {

  const { id } = useParams();

  const [organism, setOrganism] = useState(null);
  const [tab, setTab] = useState("Samples");
  const [samplesPage, setSamplesPage] = useState(1);
  const [samplesCount, setSamplesCount] = useState(null);
  const [samples, setSamples] = useState(null);
  const [samplesFilter, setSamplesFilter] = useState(null);
  const [hasSamples, setHasSamples] = useState(null);
  const samplesPageSize = 20;

  const { loading, status } = useFetch(`/organisms/${id}`, {
    onCompleted: data => setOrganism(data)
  });

  useDocumentTitle(organism ? `${organism.name} - Flow` : "Flow");

  const { loading: samplesLoading } = useFetch(`/organisms/${id}/samples`, {
    params: {page: samplesPage, count: samplesPageSize, filter: samplesFilter},
    onCompleted: data => {
      setSamplesCount(data.count);
      setSamples(data.samples);
      if (hasSamples === null) setHasSamples(!!data.count);
      const maxPossiblePage = Math.ceil(data.count / samplesPageSize);
      if (samplesPage > maxPossiblePage) setSamplesPage(maxPossiblePage || 1);
    }
  })

  if (loading) return <Base loading={true} />

  if (status === 404) return <Base notFound={true} />

  const latest = organism.filesets[organism.filesets.length - 1];
  const previous = organism.filesets.slice(0, -1);

  return (
    <Base>
      <ObjectTitle title={organism.name} type="organism" id={organism.id} />
      <ObjectAttributes object={organism} className="mb-4" />

      {organism.filesets.length > 0 && (
        <div className="mb-8">
          <div className="font-medium text-lg mb-2 md:text-xl">Latest Fileset</div>
          <Fileset fileset={latest} className="w-fit max-w-full" />
        </div>
      )}

      <Tabs labels={["Samples", "Filesets"]} selected={tab} setSelected={setTab} />


      {tab === "Samples" && (
        <>
          {!samplesLoading && !hasSamples && (
            <div className="text-sm mb-6 info sm:mb-8">
              This organism has no samples yet.
            </div>
          )}
          {samplesLoading && !hasSamples && (
            <div className="w-full h-48 flex justify-center items-center">
              <ClipLoader size={100} />
            </div>
          )}
          {hasSamples && (
            <SampleTable2
              samples={samples}
              page={samplesPage}
              pageSize={samplesPageSize}
              setPage={setSamplesPage}
              totalCount={samplesCount}
              loading={samplesLoading}
              onFilter={s => setSamplesFilter(s || null)}
              noMessage="No samples match that term." 
            />
          )}
        </>
      )}
      {tab === "Filesets" && (
        <div>
          {previous.length > 0 && (
            <div className="flex flex-wrap gap-4">
              {previous.map(fileset => <Fileset fileset={fileset} key={fileset.id} className="w-full max-w-md" />)}
            </div>
          )}
          {previous.length === 0 && <div className="info text-sm">No previous filesets.</div>}
        </div>
      )}
      
    </Base>
  );
};

OrganismPage.propTypes = {
  
};

export default OrganismPage;