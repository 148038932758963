import { useState } from "react";
import { PIPELINE_CONFIG } from "../queries";
import { useMutation, useQuery } from "@apollo/client";
import { ClipLoader } from "react-spinners";
import PipelineConfig from "./PipelineConfig";
import { CREATE_PIPELINE_CONFIG, CREATE_NEXTFLOW_VERSION, DELETE_NEXTFLOW_VERSION } from "../mutations";
import Button from "./Button";
import { ReactComponent as Cross } from "../images/plus.svg";

const PipelineConfigTab = () => {

  const { data, loading } = useQuery(PIPELINE_CONFIG);

  const [filename, setFilename] = useState("");
  const [contents, setContents] = useState("");
  const [nextflowVersion, setNextflowVersion] = useState("");

  const canCreate = filename && contents && !data.pipelineConfigs.find(c => c.filename === filename);

  const [createConf, createConfMutation] = useMutation(CREATE_PIPELINE_CONFIG, {
    variables: { filename, contents },
    refetchQueries: [{ query: PIPELINE_CONFIG }],
    awaitRefetchQueries: true,
    onCompleted: () => {
      setFilename("");
      setContents("");
    }
  });

  const [createNextflowVersion] = useMutation(CREATE_NEXTFLOW_VERSION, {
    variables: { version: nextflowVersion },
    refetchQueries: [{ query: PIPELINE_CONFIG }],
    awaitRefetchQueries: true,
    onCompleted: () => {
      setNextflowVersion("");
    }
  });

  const [deleteNextflowVersion] = useMutation(DELETE_NEXTFLOW_VERSION, {
    refetchQueries: [{ query: PIPELINE_CONFIG }],
    awaitRefetchQueries: true,
  });

  if (loading) return <ClipLoader />;

  return (
    <div className="flex flex-col gap-6">

      <div className="border px-4 py-3 rounded bg-slate-100 mb-8">
        <h2 className="text-lg font-medium mb-2">Nextflow Versions</h2>
        <div className="flex flex-wrap items-start gap-2 w-full">
          {data.nextflowVersions.map(v => (
            <div key={v} className="flex items-center gap-1.5 text-sm font-medium border w-fit rounded bg-flow-gray-2 border-flow-gray-5 px-2 py-px">
              <div>{v}</div>
              <button
                className="text-red-700 text-2xs"
                onClick={() => deleteNextflowVersion({ variables: { version: v } })}
              >
                <Cross className="w-2.5 h-2.5 fill-red-700 rotate-45 hover:fill-red-600" />
              </button>
            </div>
          ))}
        </div>
        <form onSubmit={e => {
          e.preventDefault();
          createNextflowVersion();
        }}>
          <input
            type="text"
            value={nextflowVersion}
            onChange={e => setNextflowVersion(e.target.value)}
            placeholder="New Version"
            className="px-0 text-sm block w-36 border-b border-flow-gray-5 rounded-none mt-2 bg-transparent"
          />
        </form>
      </div>

      {data.pipelineConfigs.map(config => <PipelineConfig key={config.filename} config={config} />)}
      <div>
        <input
          type="text"
          value={filename}
          onChange={e => setFilename(e.target.value)}
          placeholder="Filename"
          className="px-0"
        />
        <textarea
          value={contents}
          onChange={e => setContents(e.target.value)}
          className="bg-slate-800 text-white font-mono text-sm whitespace-pre w-full h-48 py-2 px-4"
        />
        <Button className={`btn-primary ${canCreate ? "" : "opacity-50 pointer-events-none"}`} loading={createConfMutation.loading} onClick={createConf}>
          Create
        </Button>
      </div>
    </div>
  );
};

PipelineConfigTab.propTypes = {
  
};

export default PipelineConfigTab;