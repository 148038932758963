import { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useApolloClient } from "@apollo/client";
import { TOKEN } from "../queries";
import { ClipLoader } from "react-spinners";
import { useLazyFetch, useFetch } from "../hooks";

const DataContent = props => {

  const { data } = props;

  const [contents, setContents] = useState("");
  const position = useRef(0);
  const client = useApolloClient();

  const fetchMore = () => {
    position.current += 1;
    getMoreContents({
      params: {position: position.current},
      onCompleted: data => setContents(contents.concat(data.contents))
    });
  }

  const isImg = ["jpg", "png", "gif", "jpeg"].includes(data.filetype.toLowerCase());
  const isPdf = data.filetype.toLowerCase() === "pdf";
  const isHtml = data.filetype.toLowerCase() === "html";
  const isVideo = ["mp4", "mov", "mpg"].includes(data.filetype.toLowerCase());

  const needPlainText = !isImg && !isPdf && !isHtml && !isVideo && !data.is_binary && !data.is_directory;

  useFetch(`/data/${data.id}/contents`, {
    onCompleted: data => setContents(data.contents),
    skip: !needPlainText
  });

  const [{ loading }, getMoreContents] = useLazyFetch(`/data/${data.id}/contents`);


  if (isImg || isPdf || isHtml || isVideo) {
    let token;
    try {
      const cacheValue = client.cache.readQuery({query: TOKEN});
      token = `?token=${cacheValue.accessToken}&direct=yes`;
    } catch {
      token = "?direct=yes";
    }

    const className = "border border-[#F2F2F2] shadow w-full";
    const cappedClass = `${className} max-w-5xl`;
    const src = `${process.env.REACT_APP_DOWNLOADS}/${data.id}/${data.filename}${token}`;

    if (isImg) {
      return <img src={src} alt="" className={cappedClass} />
    } else if (isVideo) {
      return (
        <video className={cappedClass} controls>
          <source src={src} type={`video/${data.filetype.toLowerCase()}`} />
        </video>
      )
    } else {
      return <iframe title="htmlView" src={src} className={`${className} h-screen`} />
    }
  }

  if (!contents && !data.is_directory && !data.is_binary) {
    return (
      <div className="w-full flex justify-center items-center py-12"><ClipLoader size="120px" /></div>
    )
  }

  if (contents) {
    const lines = contents.split("\n");
    const isMore = data.size > contents.length && contents.length % 1024 === 0;
    if (lines.length > 1 && isMore) lines.splice(lines.length - 1, 1);

    return (
      <div>
        <div className="flex w-full font-mono text-2xs border-2 border-opacity-40 rounded-lg overflow-hidden whitespace-nowrap sm:text-xs">
          <div className="bg-[#E7E9F0]">
            {Object.keys(lines).map(index => (
              <div className="border-r w-9 py-px text-right pr-1 text-2xs flex-shrink-0" key={index}>
                {parseInt(index) + 1}
              </div>
            ))}
          </div>
          <div className="overflow-auto w-full bg-[#F8F8F8]">
            {lines.map((line, i) => (
              <div key={i} className="px-1 py-px">
                {line || <span>&#8203;</span>}
              </div>
            ))}
          </div>
        </div>
        {isMore && (
          <div
            onClick={fetchMore}
            className="text-sm flex gap-1 items-center cursor-pointer mt-2 hover:underline"
          >
            <span>Show More</span>
            {loading && <ClipLoader size="14px" />}
          </div>
        )}
      </div>
    )
  }

  return (
    <div className="bg-flow-gray-2 rounded text-flow-gray-7 text-sm flex justify-center items-center h-48 px-4 md:h-72 lg:h-96">
      This data cannot be previewed because it is {" "}
      {data.is_directory ? "a directory" : data.is_binary ? "a binary file" : "empty"}
      {" "}- download it to view it
    </div>
  );
};

DataContent.propTypes = {
  data: PropTypes.object.isRequired
};

export default DataContent;