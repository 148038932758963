import { useContext, useState } from "react";
import PropTypes from "prop-types";
import PipelineInputsSection from "./PipelineInputsSection";
import Button from "./Button";
import { UserContext } from "../contexts";
import { useLazyFetch } from "../hooks";
import { useNavigate } from "react-router-dom";

const PipelineInputsForm = props => {

  const { schema, versionId, nextflowVersion, filesets, upstreamPipelineVersions } = props;

  const [openSections, setOpenSections] = useState(null);
  const [modes, setModes] = useState(null);
  const [params, setParams] = useState({});
  const [dataParams, setDataParams] = useState({});
  const [sampleParams, setSampleParams] = useState({});
  const [csvParams, setCsvParams] = useState({});
  const [filesetId, setFilesetId] = useState(null);
  const [organism, setOrganism] = useState(null);
  const [resequenceSamples, setResequenceSamples] = useState(false);
  const [user,] = useContext(UserContext);

  
  const defaultModes = schema.inputs.reduce((prev, curr) => {
    prev[curr.name] = curr.modes ? curr.modes[0] : null
    return prev;
  }, {});
  const modesToUse = modes || defaultModes;

  const serializeParams = (params, addDefaults, removeObj) => {
    const serialized = {};
    for (const [key, value] of Object.entries(params || {})) {
      if (value?.id) {
        serialized[key] = value.id;
      } else if (value?.data) {
        serialized[key] = {...value, data: value.data.map(d => d.id)};
      } else if (value.rows) {
        serialized[key] = JSON.parse(JSON.stringify(value));
      } else {
        serialized[key] = value;
      }
      if (removeObj) {
        for (const row of serialized[key].rows) {
          if (row.sample) row.sample = row.sample.id;
          if (row.fileset) row.fileset = row.fileset.id;
          for (const k in row.values) {
            if (row.values[k].id) row.values[k] = row.values[k].id;
          }
        }
      }
    }
    if (addDefaults) {
      for (let category of schema.inputs) {
        for (let [name, property] of Object.entries(category.params)) {
          if (property.default === undefined && serialized[name] === undefined && property.type === "boolean") {
            serialized[name] = "false";
          }
          if (property.default !== undefined && serialized[name] === undefined) {
            serialized[name] = property.default.toString();
          };
        }
      }
    }
    return serialized;
  }

  const navigate = useNavigate();

  const [{ loading }, runPipeline] = useLazyFetch(`/pipelines/versions/${versionId}/run`, {
    method: "POST", onCompleted: execution => navigate(`/executions/${execution.id}/`),
    params: {
      params: serializeParams(params, true),
      data_params: serializeParams(dataParams),
      csv_params: serializeParams(csvParams, false, true),
      nextflow_version: nextflowVersion,
      fileset: filesetId,
      resequence_samples: resequenceSamples,
    }
  });

  const requiredParams = [...schema.inputs].reduce(
    (p, c) => [...p, ...[...Object.entries(c.params)].filter(
      ([,prop]) => prop.required && !prop.default && !(prop.type === "hidden") && ![
        "data", "csv",
      ].includes(prop.type) && (!modesToUse[c.name] || prop.modes?.includes(modesToUse[c.name]))
    ).map(([name,]) => name)], []
  )

  const requiredDataParams = [...new Set([...schema.inputs].reduce(
    (p, c) => [...p, ...[...Object.entries(c.params)].filter(
      ([,prop]) => prop.required && !(prop.type === "hidden") && ["data"].includes(prop.type) && (!modesToUse[c.name] || prop.modes?.includes(modesToUse[c.name]))
    ).map(([name, obj]) => obj.param || name)], []
  ))]

  const requiredCsvParams = [...new Set([...schema.inputs].reduce(
    (p, c) => [...p, ...[...Object.entries(c.params)].filter(
      ([,prop]) => prop.required && !(prop.type === "hidden") && prop.type === "csv" && (!modesToUse[c.name] || prop.modes?.includes(modesToUse[c.name]))
    ).map(([name, obj]) => obj.param || name)], []
  ))]

  const gotRequiredParams = requiredParams.length === 0 || !requiredParams.map(name => name in params && params[name] !== undefined).some(p => !p);

  const gotRequiredDataParams = requiredDataParams.length === 0 || !requiredDataParams.map(name => name in dataParams && dataParams[name] !== undefined).some(p => !p);

  const gotRequiredCsvParams = requiredCsvParams.length === 0 || !requiredCsvParams.map(name => name in csvParams && csvParams[name] !== undefined).some(p => !p);

  const gotAllRequiredParams = gotRequiredParams && gotRequiredDataParams && gotRequiredCsvParams;
  const canRun = user && gotAllRequiredParams && user.can_run_pipelines;

  return (
    <div>
      <div className="flex flex-col mb-20">
        {schema.inputs.map((section, index) => (
          <div key={index} className={`max-w-7xl border-flow-gray-3 pt-8 pb-10 border-y ${index === 0 ? "" : "-mt-px"}`}>
            <PipelineInputsSection
              section={section} name={section.name}
              modes={modesToUse} setModes={setModes}
              params={params} setParams={setParams}
              dataParams={dataParams} setDataParams={setDataParams}
              sampleParams={sampleParams} setSampleParams={setSampleParams}
              csvParams={csvParams} setCsvParams={setCsvParams}
              openSections={openSections || schema.inputs.filter(section => !section.advanced).map(section => section.name)}
              setOpenSections={setOpenSections}
              filesets={filesets} filesetId={filesetId} setFilesetId={setFilesetId}
              organism={organism} setOrganism={setOrganism}
              upstreamPipelineVersions={upstreamPipelineVersions}
              resequenceSamples={resequenceSamples} setResequenceSamples={setResequenceSamples}
            />
          </div>
        ))}
      </div>
      <Button
        className={`btn-primary px-8 py-2 ${canRun || "bg-[#D4D4D4] border-[#D4D4D4] pointer-events-none"}`}
        loading={loading}
        onClick={runPipeline}
      >
        {user ? user.can_run_pipelines ? "Run Pipeline" : "Your account cannot run pipelines" : "Sign up or log in to run"}
      </Button>
    </div>
  );
};

PipelineInputsForm.propTypes = {
  schema: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired,
  nextflowVersion: PropTypes.string.isRequired,
  filesets: PropTypes.array,
  upstreamPipelineVersions: PropTypes.array,
};

export default PipelineInputsForm;