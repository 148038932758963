import React, { useState } from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../contexts";
import { ReactComponent as DownArrow } from "../images/down.svg";

const SiteLinks = () => {

  const [visibleIndex, setVisibleIndex] = useState(null);
  const [user,] = useContext(UserContext);

  const sections = [{
    title: "Your work",
    user: true,
    links: [
      {title: "Projects", to: "/projects/", user: true},
      {title: "Samples", to: "/samples/", user: true},
      {title: "Executions", to: "/executions/", user: true},
      {title: "Data", to: "/data/", user: true},
    ]
  }, {
    title: "Actions",
    user: false,
    links: [
      {title: "Upload Data", to: "/upload/", user: true},
      {title: "New Project", to: "/projects/new/", user: true},
      {title: "Run Analysis", to: "/pipelines/", user: false},
    ]
  }, {
    title: "Explore",
    user: false,
    links: [
      {title: "Public Projects", to: "/projects/public/", user: false},
      {title: "Public Samples", to: "/samples/public/", user: false},
      {title: "Organisms", to: "/organisms/", user: false},
      {title: "PEKA", to: "/peka/", user: false},
      {title: "Documentation", to: "https://docs.flow.bio/", user: false},
    ]
  }]

  return (
    <div className="px-8 sm:px-0 sm:w-fit sm:flex sm:flex-col sm:gap-3.5">
      {sections.filter(s => user || !s.user).map((section, index) => (
        <div key={index} className="sm:flex sm:flex-col">
          <div
            onClick={() => setVisibleIndex(visibleIndex === index ? null : index)}
            className="font-medium text-white text-lg border-b py-1.5 border-flow-purple-2 flex justify-between items-center cursor-pointer sm:text-flow-blue-6 sm:border-0 sm:py-0 sm:cursor-auto sm:mb-0.5 sm:text-base"
          >
            {section.title}
            <DownArrow fill="#9499FF" className={`sm:hidden ${visibleIndex !== index || "rotate-180"}`}/>
          </div>
          <div
            className="hidden bg-white bg-opacity-5 px-4 py-1 border-b border-flow-purple-2 sm:block sm:bg-none sm:px-0 sm:py-0 sm:border-0"
            style={{display: visibleIndex === index ? "block" : undefined}}
          >
            {section.links.filter(l => user || !l.user).map(link => {
              const Element = link.to.slice(0, 4) === "http" ? "a" : Link;
              const to = {[link.to.slice(0, 4) === "http" ? "href" : "to"]: link.to}
              return (
                <Element {...to} key={link.to} className="block text-base hover:no-underline py-1 font-medium sm:hover:bg-[#f0f1f5] sm:text-flow-blue-2 sm:py-0.5 sm:relative sm:-left-2 sm:pl-2 sm:pr-2 sm:rounded sm:text-sm sm:font-normal">
                  {link.title}
                </Element>
              )
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

SiteLinks.propTypes = {

};

export default SiteLinks;