import { useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as ProcessesIcon } from "../images/processes.svg";
import ProcessExecutions from "./ProcessExecutions";

const ExecutionProcessExecutions = props => {

  const { execution } = props;

  const [filterText, setFilterText] = useState("");
  const [expandedIdentifiers, setExpandedIdentifiers] = useState([]);

  const expandAll = () => {
    setExpandedIdentifiers(execution.process_executions.map(procEx => procEx.identifier));
  }

  const collapseAll = () => {
    setExpandedIdentifiers([]);
  }

  const filteredProcessExecutions = filterText ? execution.process_executions.filter(procEx => {
    const name = procEx.name.toLowerCase();
    const identifier = procEx.identifier.toLowerCase();
    const stdout = procEx.stdout ? procEx.stdout.toLowerCase() : "";
    const stderr = procEx.stderr ? procEx.stderr.toLowerCase() : "";
    const filter = filterText.toLowerCase();
    return name.includes(filter) || identifier.includes(filter) || stdout.includes(filter) || stderr.includes(filter);
  }) : execution.process_executions;

  let message = "";
  if (filterText && execution.process_executions.length > 0) {
    message = "No processes match this filter";
  } else {
    message = execution.finished ? "No processes executed" : "No processes executed yet";
  }

  const sampleIds = [];
  for (const procEx of filteredProcessExecutions) {
    if (procEx.sample && !sampleIds.includes(procEx.sample.id)) {
      sampleIds.push(procEx.sample.id);
    }
  }
  
  const baseProcesses = sampleIds.length > 1 ? (
    filteredProcessExecutions.filter(procEx => !procEx.sample)
  ) : filteredProcessExecutions;

  const buttonClass = "text-xs text-flow-blue-7";
  const canExpandAll = expandedIdentifiers.length < execution.process_executions.length;
  const canCollapseAll = expandedIdentifiers.length > 0;

  return (
    <div className="w-full sm:max-w-xl">
      <div className="flex gap-2 items-center font-medium text-lg mb-3 md:text-xl">
        <ProcessesIcon />Process Executions
      </div>

      {execution.process_executions.length > 1 && (
        <div className="mb-3 flex gap-2">
          <input
            type="text"
            placeholder="Filter..."
            className="rounded-none border-b text-xs px-0 py-0.5"
            value={filterText}
            onChange={e => setFilterText(e.target.value)}
          />
          <button onClick={expandAll} className={`${buttonClass} ${canExpandAll ? "" : "opacity-50 pointer-events-none"}`}>
            Expand All
          </button>
          <button onClick={collapseAll} className={`${buttonClass} ${canCollapseAll ? "" : "opacity-50 pointer-events-none"}`}>
            Collapse All
          </button>
        </div>
      )}

      <div className="flex flex-col gap-8">
        <ProcessExecutions
          processExecutions={baseProcesses}
          execution={execution}
          expandedIdentifiers={expandedIdentifiers}
          setExpandedIdentifiers={setExpandedIdentifiers}
        />
        {sampleIds.map(sampleId => {
          const sampleProcessExecutions = filteredProcessExecutions.filter(procEx => procEx.sample?.id === sampleId);
          const sample = sampleProcessExecutions[0].sample;
          return (
            <ProcessExecutions
              key={sampleId}
              processExecutions={sampleProcessExecutions}
              execution={execution}
              expandedIdentifiers={expandedIdentifiers}
              setExpandedIdentifiers={setExpandedIdentifiers}
              sample={sample}
            />
          );
        })}
      </div>
      {filteredProcessExecutions.length === 0 && (
        <div className="italic">{message}.</div>
      )}
    </div>
  );
};

ExecutionProcessExecutions.propTypes = {
  execution: PropTypes.object.isRequired
};

export default ExecutionProcessExecutions;