import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { UserContext, TokenContext } from "../contexts";
import { useApolloClient } from "@apollo/client";
import { TOKEN } from "../queries";
import { Link } from "react-router-dom";
import UserImage from "./UserImage";
import { useLazyFetch } from "../hooks";

const UserMenu = props => {

  const { mobile } = props;

  const [user, setUser] = useContext(UserContext);
  const tokenRef = useContext(TokenContext);

  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef();

  const client = useApolloClient();

  useEffect(() => {
    const onClick = e => {
      if (ref.current && ref.current.contains(e.target)) return;
      setIsOpen(false);
    }
    window.addEventListener("click", onClick);
    return () => {
      window.removeEventListener("click", onClick);
    }
  }, [])

  const [,logout] = useLazyFetch("/logout", {
    method: "POST",
    onCompleted: () => {
      setUser(false);
      tokenRef.current = null;
      client.cache.writeQuery({query: TOKEN, data: {accessToken: null}});
      client.cache.reset();
    }
  })

  if (mobile) {
    return (
      <div className="px-8 font-medium flex flex-col gap-3 mt-4 pb-2">
        <Link to={`/users/${user.username}/`} onClick={() => setIsOpen(false)}>
          Profile
        </Link>
        <Link to="/settings/">Settings</Link>
        {user.is_admin && <Link to="/admin/">Admin</Link>}
        <div className="ml-auto cursor-pointer" onClick={logout}>Log Out</div>
      </div>
    )
  }

  return (
    <div className="relative hidden sm:block" ref={ref}>
      <UserImage user={user} className="w-10 h-10 cursor-pointer" onClick={() => setIsOpen(!isOpen)} />
      {isOpen && (
        <div className="absolute z-40 -right-2 top-16 bg-[#FDFDFD] shadow py-5 border px-12 rounded w-60">
          <div className="w-16 h-8 overflow-hidden rotate-180 transform absolute -top-8 right-2">
            <div className="h-7 w-7 bg-[#FDFDFD] border shadow-sm -rotate-45 transform origin-top-left" />
          </div>
          <div className="text-flow-blue-7 border-b pb-3 mb-3">
            <div className="text-lg font-semibold">{user.name}</div>
            <div className="text-sm font-medium">{user.username}</div>
          </div>
          <div className="text-flow-gray-8 text-base flex flex-col gap-1">
            <Link to={`/users/${user.username}/`} onClick={() => setIsOpen(false)}>
              Profile
            </Link>
            {user.is_admin && <Link to="/admin/">Admin</Link>}
            <Link to="/settings/">Settings</Link>
            <div className="link" onClick={logout}>Log Out</div>
          </div>
        </div>
      )}
    </div>
  );
};

UserMenu.propTypes = {
  mobile: PropTypes.bool
};

export default UserMenu;