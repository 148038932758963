import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";
import { TokenContext } from "../contexts";
import { ClipLoader } from "react-spinners";

const CsvInputModal = props => {

  const { setShow, executionId, paramName } = props;

  const tokenRef = useContext(TokenContext);

  const [csv, setCsv] = useState(null);
  
  let token;
  try {
    token = `?token=${tokenRef.current}`;
  } catch {
    token = "";
  }

  useEffect(() => {
    fetch(`${process.env.REACT_APP_EXECUTIONS}/${executionId}/${paramName}.csv${token}`, {
    }).then(
      response => response.text()
    ).then(setCsv);
  }, [executionId, paramName, token]);

  const rows = csv ? csv.trim().split("\n").slice(0).map(row => row.split(",")) : [];

  const cellClass = "whitespace-nowrap text-xs text-left px-3 py-1";
  const tdClass = `${cellClass}`;
  
  return (
    <Modal
      setShowModal={setShow}
      closable={true}
      className="max-w-[calc(100vw-3rem)] w-fit font-mono min-h-[4rem] flex flex-col justify-center"
    >
      {!csv && (
        <div className="w-full flex justify-center items-center py-10">
          <ClipLoader size={80} speedMultiplier={1.5} />
        </div>
      )}
      {csv && (
        <div className="border overflow-auto rounded-md">
          <table className="border-0">
            <tbody>
              {rows.map((row, rowIndex) => (
                <tr key={rowIndex} className={`${rowIndex % 2 ? "bg-[#E7E9F0]" : "bg-[#F6F6F6]"}`}>
                  {row.map((cell, cellIndex) => (
                    <td key={cellIndex} className={tdClass}>{cell}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </Modal>
  );
};

CsvInputModal.propTypes = {
  setShow: PropTypes.func.isRequired,
  executionId: PropTypes.number.isRequired,
  paramName: PropTypes.string.isRequired
};

export default CsvInputModal;