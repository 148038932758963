import React, { useRef } from "react";
import PropTypes from  "prop-types";

const Modal = props => {

  const { setShowModal, title, text, closable } = props;

  const ref = useRef();

  const dismiss = e => {
    if (!ref.current.contains(e.target)) setShowModal(false);
  }

  const closeClass = "h-full w-1 mx-auto bg-gray-400 absolute left-0 right-0";

  return (
    <div className="fixed left-0 top-0 w-full h-full flex py-8 bg-opacity-40 justify-center bg-gray-700 z-50" onClick={dismiss}>
      <div className={`bg-white shadow-lg rounded-md p-7 max-h-full my-auto mx-6 z-50 relative ${props.className || ""}`} ref={ref} style={props.style || null}>
        {title && <div className={`text-xl text-center font-medium mb-2 sm:text-2xl sm:mb-3`}>{title}</div>}
        {text && <div className="text-primary-700 mb-2 text-center px-1 font-normal whitespace-normal text-sm sm:text-base sm:mb-4">{text}</div>}
        {props.children}
        {closable && (
          <div className="absolute top-1.5 right-1.5 w-5 h-5 opacity-50 hover:opacity-70 cursor-pointer" onClick={() => setShowModal(false)}>
            <div className={`${closeClass} rotate-45`} />
            <div className={`${closeClass} -rotate-45`} />
          </div>
        )}
      </div>
    </div>
  );
};

Modal.propTypes = {
  setShowModal: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  text: PropTypes.string,
  closable: PropTypes.bool,
};

export default Modal;