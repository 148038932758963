import React from "react";
import PropTypes from "prop-types";
import { ClipLoader } from "react-spinners";

const ExecutionStatus = props => {

  const { execution } = props;

  const isProcess = Boolean(execution.process_name);

  const boxClass = `rounded mt-0.5 text-xs text-white flex items-center flex-shrink-0 gap-1.5 w-6 h-6 rounded-full sm:h-auto sm:rounded sm:text-sm sm:w-fit ${isProcess ? "sm:py-0.5 sm:px-2" : "sm:py-1 sm:px-3"}`;
  const spinnerClass = "flex font-medium gap-2 pt-1 items-center";
  const iconClass = `w-3.5 h-auto mx-auto sm:w-4`;
  const spinnerSize = 20;


  if (!execution.started) {
    return (
      <div className={`${spinnerClass} text-flow-gray-7`}>
        <span className="hidden sm:inline">{!isProcess && "Waiting"}</span> <ClipLoader color="#777777" size={spinnerSize} speedMultiplier={0.8} />
      </div>
    )
  }

  if (!execution.finished) {
    return (
      <div className={spinnerClass}>
        <span className="hidden sm:inline">{!isProcess && "Running"}</span> <ClipLoader color="#37474F" size={spinnerSize} speedMultiplier={1.2} />
      </div>
    )
  }

  if (execution.status === "OK" || execution.status === "COMPLETED") {
    return (
      <div className={`${boxClass} bg-flow-green-1`}>
        <span className="hidden sm:inline">Completed</span>
        <svg viewBox="0.5 0 11.3 11" className={iconClass}>
          <path d="M6.06932 0.0625C3.07871 0.0625 0.631836 2.50937 0.631836 5.49999C0.631836 8.4906 3.07871 10.9375 6.06932 10.9375C9.05994 10.9375 11.5068 8.4906 11.5068 5.49999C11.5068 2.50937 9.05996 0.0625 6.06932 0.0625ZM6.06932 9.79563C3.73121 9.79563 1.77371 7.89249 1.77371 5.49999C1.77371 3.16187 3.67684 1.20437 6.06932 1.20437C8.4618 1.20437 10.3649 3.10751 10.3649 5.49999C10.365 7.83813 8.40746 9.79563 6.06932 9.79563Z" fill="white"/>
          <path d="M4.98142 6.15406L3.78516 4.95782L3.02393 5.71908L4.98142 7.67658L5.74266 6.91532L8.84203 3.81595L8.0808 3.05469L4.98142 6.15406Z" fill="white"/>
        </svg>
      </div>
    )
  }

  if (execution.status === "CANCELED") {
    return (
      <div className={`${boxClass} bg-gray-500`}>
        <span className="hidden sm:inline">Canceled</span>
        <svg className={iconClass} enableBackground="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg">
          <g><path d="m256 0c-140.7 0-256 115.3-256 256s115.3 256 256 256 256-115.3 256-256-115.3-256-256-256zm-167.999 354.399c-17.1-28.799-27.001-62.399-27.001-98.399 0-107.1 86.4-194.7 194.399-195 36.301 0 69.901 9.899 99.001 26.999 8.699 5.101 10.499 16.501 3.6 23.701-45.9 45.899-200.401 200.4-246.3 246.299-7.2 6.901-18.6 5.101-23.699-3.6zm168.6 96.601c-36.301 0-69.901-9.901-99.001-27.001-8.699-5.099-10.499-16.8-3.3-23.699l246-246c6.899-7.2 18.6-5.4 23.699 3.3 17.1 28.8 27.001 62.4 27.001 98.4 0 107.699-87.001 194.7-194.399 195z" fill="white" /></g>
        </svg>
      </div>
    )
  }

  return (
    <div className={`${boxClass} bg-flow-red-3`}>
      <span className="hidden sm:inline">Error</span>
      <svg viewBox="0.1 0.5 11.4 11" className={iconClass}>
        <path d="M5.77594 11.4401C2.77628 11.4401 0.335938 8.99971 0.335938 6.00006C0.335938 3.00041 2.77628 0.560059 5.77594 0.560059C8.77559 0.560059 11.2159 3.00041 11.2159 6.00006C11.2159 8.99971 8.77559 11.4401 5.77594 11.4401ZM5.77594 1.33718C3.20485 1.33718 1.11306 3.42897 1.11306 6.00006C1.11306 8.57114 3.20485 10.6629 5.77594 10.6629C8.34702 10.6629 10.4388 8.57114 10.4388 6.00006C10.4388 3.42897 8.34702 1.33718 5.77594 1.33718Z" fill="white"/>
        <path d="M7.71888 8.33226C7.61944 8.33226 7.51999 8.29433 7.44412 8.21838L3.55845 4.3327C3.40662 4.18088 3.40662 3.93501 3.55845 3.78327C3.71018 3.63153 3.95614 3.63145 4.10787 3.78327L7.99355 7.66895C8.14537 7.82077 8.14537 8.06664 7.99355 8.21838C7.91768 8.29433 7.81832 8.33226 7.71888 8.33226Z" fill="white"/>
        <path d="M3.83312 8.33229C3.73368 8.33229 3.63432 8.29436 3.55845 8.21841C3.40662 8.06667 3.40662 7.82072 3.55845 7.66898L7.44412 3.7833C7.59595 3.63148 7.84181 3.63148 7.99355 3.7833C8.14529 3.93504 8.14537 4.18099 7.99355 4.33273L4.10787 8.21841C4.03201 8.29436 3.93256 8.33229 3.83312 8.33229Z" fill="white"/>
      </svg>

    </div>
  )
};

ExecutionStatus.propTypes = {
  execution: PropTypes.object.isRequired
};

export default ExecutionStatus;