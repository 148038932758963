import React from "react";
import PropTypes from "prop-types";

const Toggle = props => {

  const { value, onChange, trueLabel, falseLabel, labelClass, small } = props;

  const fullLabelClass = `cursor-pointer text-sm ${labelClass || ""}`;
  const activeClass = `${fullLabelClass} text-flow-blue-7`;

  return (
    <div className={`flex items-center gap-1.5 ${props.className || ""}`}>
      {trueLabel && (
        <div onClick={() => onChange(true)} className={value ? activeClass : fullLabelClass}>
          {trueLabel}
        </div>
      )}
    
      <div
        className={` bg-[#F1F1F1] rounded-xl relative cursor-pointer ${small ? "w-12 h-5 p-0.5" : "w-20 p-1 h-7"}`}
        onClick={() => onChange(!value)}
      >
        <div className={`absolute rounded-full border transition-all duration-150 ease-in-out bg-flow-blue-7 ${small ? "w-4 h-4" : "w-5 h-5"} ${value ? "left-0.5" : small ? "left-7 ml-0.5" : "left-14"}`} />
      </div>

      {falseLabel && (
        <div onClick={() => onChange(false)} className={value ? fullLabelClass : activeClass}>
          {falseLabel}
        </div>
      )}
    </div>
  );
};

Toggle.propTypes = {
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  trueLabel: PropTypes.string,
  falseLabel: PropTypes.string,
  labelClass: PropTypes.string,
  small: PropTypes.bool,
};

export default Toggle;