import React from "react";
import PropTypes from "prop-types";

const MobileMenuToggle = props => {

  const { isOpen, setIsOpen, toggleRef } = props;

  return (
    <div className="flex flex-col gap-1 h-7 w-9 relative cursor-pointer sm:hidden" onClick={() => setIsOpen(!isOpen)} ref={toggleRef}>
      {[0, 1, 2].map(n => {
        const hide = isOpen && n === 1 ? "hidden" : "";
        const center = isOpen ? "absolute top-3 bottom-3 left-0" : "relative";
        const angle = isOpen ? n === 0 ? "rotate-45" : "-rotate-45" : "";
        return (
          <div key={n} className={`w-9 h-1.5 bg-flow-blue-7 ${hide} ${center} ${angle}`}>
            <div className="absolute h-2 w-0.5 rounded-sm -top-px bg-flow-blue-8" />
            <div className="absolute h-2 w-0.5 rounded-sm -top-px bg-flow-blue-8 -right-0" />
          </div>
        )
      })}
    </div>
  );
};

MobileMenuToggle.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  toggleRef: PropTypes.object.isRequired
};

export default MobileMenuToggle;