import { useState } from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import { CREATE_ORGANISM, UPDATE_ORGANISM } from "../mutations";
import { ORGANISMS_ADMIN } from "../queries";
import { useMutation } from "@apollo/client";
import { parseError } from "../errors";

const OrganismForm = props => {

  const { organism, setShow } = props;

  const [id, setId] = useState(null);
  const [name, setName] = useState(null);
  const [latinName, setLatinName] = useState(null);
  const [errors, setErrors] = useState(null);

  const idText = id === null ? organism ? organism.id : "" : (id || "");
  const nameText = name === null ? organism ? organism.name : "" : (name || "");
  const latinNameText = latinName === null ? organism ? organism.latinName : "" : (latinName || "");

  const [createOrganism, createOrganismMutation] = useMutation(CREATE_ORGANISM, {
    variables: {id: idText, name: nameText, latinName: latinNameText},
    refetchQueries: [{query: ORGANISMS_ADMIN}],
    awaitRefetchQueries: true,
    onCompleted: () => setShow(false),
    onError: error => setErrors(parseError(error))
  });

  const [updateOrganism, updateOrganismMutation] = useMutation(UPDATE_ORGANISM, {
    variables: {id: organism?.id, name: nameText, latinName: latinNameText},
    refetchQueries: [{query: ORGANISMS_ADMIN}],
    awaitRefetchQueries: true,
    onCompleted: () => setShow(false),
    onError: error => setErrors(parseError(error))
  });

  const onSubmit = e => {
    e.preventDefault();
    if (organism) updateOrganism();
    if (!organism) createOrganism();
  };

  const loading = createOrganismMutation.loading || updateOrganismMutation.loading;

  const blockClass = "w-48";
  const labelClass = "text-flow-gray-9 font-medium text-sm block mb-1.5";
  const inputClass = "block bg-flow-gray-2 text-flow-blue-7 rounded text-xs px-3 py-1.5 w-full sm:text-sm";

  return (
    <form onSubmit={onSubmit} className="py-6">
      <div className="flex flex-wrap gap-x-8 gap-y-4 mb-6">
        <div className={`${blockClass} ${organism ? "pointer-events-none opacity-50" : ""}`}>
          <label className={labelClass} htmlFor="id">ID</label>
          {errors && errors.validation?.id && (
            <div className="text-red-500 text-xs mb-2">{errors.validation.id[0]}</div>
          )}
          <input
            id="id"
            type="text"
            value={idText}
            onChange={e => setId(e.target.value)}
            className={inputClass}
            autoCapitalize="none"
            required
          />
        </div>
        <div className={blockClass}>
          <label className={labelClass} htmlFor="name">Name</label>
          {errors && errors.validation?.name && (
            <div className="text-red-500 text-xs mb-2">{errors.validation.name[0]}</div>
          )}
          <input
            id="name"
            type="text"
            value={nameText}
            onChange={e => setName(e.target.value)}
            className={inputClass}
            autoCapitalize="none"
            required
          />
        </div>
        <div className={blockClass}>
          <label className={labelClass} htmlFor="latinName">Latin Name</label>
          {errors && errors.validation?.latinName && (
            <div className="text-red-500 text-xs mb-2">{errors.validation.latinName[0]}</div>
          )}
          <input
            id="latinName"
            type="text"
            value={latinNameText}
            onChange={e => setLatinName(e.target.value)}
            className={`italic ${inputClass}`}
            autoCapitalize="none"
          />
        </div>
      </div>
      <div className="flex gap-2">
        <Button type="submit" className="btn-primary text-white" loading={loading}>Save</Button>
        <Button type="button" className="btn-secondary text-flow-blue-2" onClick={() => setShow(false)}>Cancel</Button>
      </div>
    </form>
  );
};

OrganismForm.propTypes = {
  organism: PropTypes.object,
  setShow: PropTypes.func.isRequired
};

export default OrganismForm;