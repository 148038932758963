import { useId, useRef, useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as FileIcon } from "../images/upload-box.svg";
import { ReactComponent as ZipIcon } from "../images/zip.svg";
import { ReactComponent as CrossIcon } from "../images/plus.svg";
import { fileSize } from "../utils";
import ProgressBar from "./ProgressBar";
import { Link } from "react-router-dom";

const FileInput = props => {

  const { files, setFiles, multiple, accept, allowDirectories, progress, setProgress, dataIds, setDataIds, paired, error } = props;

  const [hovering1, setHovering1] = useState(false);
  const [hovering2, setHovering2] = useState(false);
  const ref1 = useRef();
  const ref2 = useRef();
  const id = useId();

  const dragEnter = (e, index) => {
    e.preventDefault();
    index === 1 ? setHovering1(true) : setHovering2(true);
  }

  const dragLeave = (e, index) => {
    e.preventDefault();
    index === 1 ? setHovering1(false) : setHovering2(false);
  }

  const dragOver = e => {
    e.preventDefault();
  }

  const drop = (e, index) => {
    e.preventDefault();
    const files = multiple ? [...e.dataTransfer.files] : [e.dataTransfer.files[0]];
    if (accept) {
      const acceptable = accept.split(",");
      if (!files.every(f => acceptable.some(ext => f.name.slice(-ext.length) === ext))) {
        return
      }
    }
    const onChange = index === 1 ? onChange1 : onChange2;
    onChange({target: {files: files}});
    index === 1 ? setHovering1(false) : setHovering2(false);
  }

  const onChange1 = e => {  
    if (!multiple) {
      if (files.length) {
        setFiles([{file1: e.target.files[0], file2: files[0].file2}]);
      } else {
        setFiles([{file1: e.target.files[0], file2: null}]);
      }
    } else {
      const newFiles = [...e.target.files].filter(
        f => !files.map(f => f.file1?.name).includes(f.name)
      ).map(f => ({file1: f, file2: null}));
      setFiles([...(progress ? [] : files), ...newFiles]);
    }
    ref1.current.value = "";
  }

  const onChange2 = e => {
    if (!multiple) {
      if (files.length) setFiles([{file1: files[0].file1, file2: e.target.files[0]}]);
    } else {
      const newFiles = [...e.target.files].filter(
        f => !files.map(f => f.file2?.name).includes(f.name)
      )
      for (const file of files) {
        if (!file.file2) file.file2 = newFiles.shift();
      }
      setFiles([...files]);
    }
    ref2.current.value = "";
  }

  const removeFile = file => {
    setFiles(files.filter(f => f.file1.name !== file.name));
    if (progress && progress[file.name]) {
      delete progress[file.name];
      setProgress({...progress});
    }
    if (dataIds && dataIds[file.name]) {
      delete dataIds[file.name];
      setDataIds({...dataIds});
    }
  }

  const removeAllFiles = () => {
    setFiles([]);
    setProgress(null);
    setDataIds({});
  }

  const toggleIsDirectory = file => {
    file.isDirectory = !file.isDirectory;
    setFiles([...files]);
  }

  const labelClass = `flex flex-col justify-center border-4 bg-[#F8F8F8] border-dashed hover:border-flow-blue-7 hover:z-40 text-center items-center px-8 py-6 cursor-pointer ${paired ? "text-base" : "text-lg"}`;

  const uploadInProgress = Object.values(progress || {}).some(p => p !== 1);

  return (
    <div className={props.className || ""}>
      <input id={`${id}1`} type="file" ref={ref1} className="hidden" onChange={onChange1} multiple={multiple} accept={accept} />
      <input id={`${id}2`} type="file" ref={ref2} className="hidden" onChange={onChange2} multiple={multiple} accept={accept} />
      <div className={`mb-4 grid ${paired ? "grid-cols-2" : ""}`}>
        {[1, 2].filter(i => i === 1 || paired).map(i => (
          <label
            key={i}
            htmlFor={`${id}${i}`}
            className={`${labelClass} ${!paired ? "rounded-xl" : i === 2 ? "-ml-1 rounded-r-xl" : "rounded-l-xl"} ${((i === 1 && hovering1) || (i === 2 && hovering2)) ? "border-flow-blue-7 z-40" : "border-[#647acf]" }`}
            onDrop={e => drop(e, i)}
            onDragEnter={e => dragEnter(e, i)}
            onDragLeave={e => dragLeave(e, i)}
            onDragOver={dragOver}
          >
            <FileIcon className="w-20 h-auto fill-[#647acf] opacity-50 mb-3 pointer-events-none" />
            <div className="pointer-events-none text-gray-600">
              <span className="font-medium underline">Click to upload</span> or drag and drop
              {paired ? ` reads ${i}` : ""}{multiple ? " files" : " file"} to upload
            </div>
          </label>
        ))}
      </div>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
          {error}
        </div>
      )}

      {files.length > 1 && (
        <button
          onClick={removeAllFiles}
          className={`ml-auto w-fit block text-sm mb-1 cursor-pointer text-flow-blue-7 ${uploadInProgress ? "opacity-0 pointer-events-none" : "" }`}
        >
          Clear All
        </button>
      )}

      <div className="flex flex-col gap-2">
        {files.map(file => (
          <div
            key={file.file1.name}
            className="border-2 rounded-lg px-2 gap-2 py-1.5 border-flow-gray-8 flex items-center"
          >
            {file.isDirectory && <ZipIcon className="w-8 px-1 h-auto fill-flow-gray-8 opacity-80 flex-shrink-0" />}
            {!file.isDirectory && <FileIcon className="w-8 h-auto fill-flow-gray-8 opacity-80 flex-shrink-0" />}
            <div className={`flex-grow grid gap-x-6 gap-y-1 ${paired ? "lg:grid-cols-2" : ""}`}>
              {[file.file1, file.file2].filter(Boolean).map(f => (
                <div key={f.name}>
                  <div className="text-flow-blue-7 pt-0.5">{f.name}</div>
                  <div className="flex text-xs gap-4">
                    <div>
                      {progress && f.name in progress && `${fileSize(f.size * progress[f.name])} / `}
                      {fileSize(f.size)}
                    </div>
                    {allowDirectories && f.name.endsWith(".zip") && (
                      <div onClick={() => toggleIsDirectory(file)} className="text-flow-blue-7 cursor-pointer">
                        Upload as {file.isDirectory ? "zip file" : "directory"}
                      </div>
                    )}
                    {dataIds && dataIds[f.name] && uploadInProgress && (
                      <a href={`/data/${dataIds[f.name]}/`} target="_blank" rel="noreferrer" className="text-flow-blue-7">
                        View file
                      </a> 
                    )}
                    {dataIds && dataIds[f.name] && !uploadInProgress && (
                      <Link to={`/data/${dataIds[f.name]}/`} className="text-flow-blue-7">View file</Link> 
                    )}
                  </div>
                  {progress && f.name in progress ? (
                    <ProgressBar progress={progress[f.name]} className="mt-1" error={error} />
                  ) : (
                    <div className="h-1 mt-1" />
                  )}
                </div>
              ))}
            </div>
            <CrossIcon
              className={`w-6 h-auto mr-2 fill-flow-gray-8 rotate-45 flex-shrink-0 cursor-pointer hover:fill-flow-blue-7 ${uploadInProgress ? "opacity-20 pointer-events-none" : ""}`}
              onClick={() => removeFile(file.file1)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

FileInput.propTypes = {
  files: PropTypes.array.isRequired,
  setFiles: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  accept: PropTypes.string,
  allowDirectories: PropTypes.bool,
  progress: PropTypes.object,
  setProgress: PropTypes.func.isRequired,
  dataIds: PropTypes.object,
  setDataIds: PropTypes.func.isRequired,
  paired: PropTypes.bool,
  error: PropTypes.string,
};

export default FileInput;