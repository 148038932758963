import PropTypes from "prop-types";
import { useFetch } from "../hooks";
import { fileSize } from "../utils";
import { ReactComponent as DirectoryIcon } from "../images/directory.svg";
import { ReactComponent as ParentDirectoryIcon } from "../images/parent-directory.svg";
import { ClipLoader } from "react-spinners";
import { Link } from "react-router-dom";

const FileSelector = props => {

  const { root, filePath, setFilePath, displayPath, setDisplayPath } = props;

  const fullDisplayPath = `${root}/${displayPath}`;

  const { loading, data: subpaths } = useFetch(`/paths/subpaths`, {params: {path: fullDisplayPath}});

  if (subpaths) {
    subpaths.sort((a, b) => {
      if (a.is_dir && !b.is_dir) return -1;
      if (!a.is_dir && b.is_dir) return 1;
      if (a.size < b.size) return 1;
      if (a.size > b.size) return -1;
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
  }

  const cellClass = "px-2 h-9 cursor-pointer whitespace-nowrap text-sm";

  const subpathClicked = subpath => {
    if (subpath.is_dir) {
      const slash = displayPath ? "/" : "";
      setDisplayPath(`${displayPath}${slash}${subpath.name}`);
    } else {
      const slash = displayPath ? "/" : "";
      const proposedPath = `${fullDisplayPath}${slash}${subpath.name}`;
      if (proposedPath === filePath) {
        setFilePath(null);
      } else {
        setFilePath(proposedPath);
      }
    }
  }

  const goUp = () => {
    const parts = displayPath.split("/");
    parts.pop();
    setDisplayPath(parts.join("/"));
  }

  return (
    <div className="w-full rounded overflow-hidden border border-[#F2F2F2] max-w-6xl">
      <div className="flex h-10 border-b border-[#F2F2F2] bg-gray-50 bg-opacity-25">
        <div
        onClick={goUp}
        className={`w-10 flex justify-center items-center ${displayPath ? "cursor-pointer" : "opacity-10 pointer-events-none"}`}
        >
          <ParentDirectoryIcon className={`w-7 h-auto ${displayPath ? "fill-flow-blue-7" : ""}`} />
        </div>
        <div className="flex items-center px-4 border-l border-[#F2F2F2] font-mono text-sm text-flow-blue-7">
          {fullDisplayPath}
        </div>
      </div>
      <div className="overflow-auto max-h-[400px] ">
        {subpaths && subpaths.length === 0 && (
          <div className="h-9 flex items-center text-xs italic px-12 bg-[#E7E9F0]">
            This directory is empty
          </div>
        )}
        {loading && (
          <div className="h-20 flex items-center justify-center bg-[#F6F6F6]">
            <ClipLoader color="#3B59C3" size={50} />
          </div>
        )}

        <table className="rounded overflow-hidden text-xs w-full">
          <tbody>
            {!loading && subpaths && subpaths.map((subpath, index) => (
              <tr
                key={subpath.name}
                className={`bg-opacity-80 hover:bg-opacity-100 ${index % 2 ? "bg-[#E7E9F0]" : "bg-[#F6F6F6]"}`}
                onClick={() => subpath.imported_id ? null : subpathClicked(subpath)}
              >
                <td className={`${cellClass} w-10`}>
                  {subpath.is_dir && <DirectoryIcon className="fill-flow-blue-7 opacity-50 w-5 h-auto" />}
                  {`${fullDisplayPath}/${subpath.name}` === filePath && (
                    <div className="w-4 h-4 rounded-full mx-auto bg-flow-blue-7" />
                  )}
                  {!subpath.is_dir && `${fullDisplayPath}/${subpath.name}` !== filePath && (
                    <div className="w-4 h-4 rounded-full mx-auto" />
                  )}
                </td>
                <td className={`${cellClass} w-full select-none`}>
                  {subpath.imported_id ? (
                    <Link to={`/data/${subpath.imported_id}`} className="text-flow-blue-7">{subpath.name}</Link>
                  ) : subpath.name}
                </td>
                <td className={cellClass}>{subpath.is_dir ? "" : fileSize(subpath.size)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

FileSelector.propTypes = {
  root: PropTypes.string.isRequired,
  filePath: PropTypes.string,
  setFilePath: PropTypes.func.isRequired,
  displayPath: PropTypes.string.isRequired,
  setDisplayPath: PropTypes.func.isRequired
};

export default FileSelector;