import FlowCounts from "../components/FlowCounts";
import LatestNews from "../components/LatestNews";
import PublicPapers from "../components/PublicPapers";
import Quickstart from "../components/Quickstart";
import Welcome from "../components/Welcome";
import { useDocumentTitle, useFetch } from "../hooks";
import Base from "./Base";

const HomePage = () => {

  useDocumentTitle("Flow");

  const headingClass = "text-flow-blue-7 font-medium text-xl mb-3 tracking-normal xl:mb-4";

  const { loading, data } = useFetch("/overview");

  const papers = loading ? null : data.public_papers;
  const pipelineCount = loading ? null : data.pipeline_count;
  const userCount = loading ? null : data.user_count;
  const sampleCount = loading ? null : data.sample_count;
  const executionCount = loading ? null : data.execution_count;
  const sampleCounts = loading ? null : data.sample_counts;
  const news = loading ? null : data.news;
  
  return (
    <Base>
      <div className="flex flex-col lg:flex-row">
        <div className="w-full max-w-xl lg:border-r lg:border-[#E0E0E0] lg:pr-5 xl:pr-10">
          <Welcome headingClass={headingClass} />
          <Quickstart headingClass={headingClass} />
          <PublicPapers headingClass={headingClass} papers={papers} />
        </div>
        <div className="w-full lg:max-w-3xl lg:pl-5 xl:pl-10">
          <LatestNews headingClass={headingClass} news={news} />
          <FlowCounts
            headingClass={headingClass} sampleCounts={sampleCounts}
            pipelineCount={pipelineCount} userCount={userCount}
            sampleCount={sampleCount} executionCount={executionCount}
          />
        </div>
      </div>
    </Base>
  );
};

HomePage.propTypes = {
  
};

export default HomePage;