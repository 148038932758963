import React, { useContext, useRef, useState } from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import { ReactComponent as PlusIcon } from "../images/plus.svg";
import { UserContext } from "../contexts";
import { useLazyFetch } from "../hooks";

const UserImageForm = props => {

  const { settings, setSettings, formClass, headingClass, buttonClass } = props;

  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState(null);
  const [user, setUser] = useContext(UserContext);
  const ref = useRef();

  const imgSrc = file ? URL.createObjectURL(file) : (settings.image && file !== "") ? `${process.env.REACT_APP_MEDIA}/${settings.image}` : "";

  const circleClass = "w-32 h-32 rounded-full";

  const fileChanged = e => {
    setFile(e.target.files[0]);
  }

  const clear = () => {
    setFile("");
    setErrors(null);
  }

  const [{ loading }, updateImage] = useLazyFetch("/settings/image", {
    method: "POST",
    form: true,
    onCompleted: settings => {
      setErrors(null);
      const newUser = {...user};
      newUser.image = settings.image;
      setUser(newUser);
      setSettings(settings);
      setFile(null);
      ref.current.value = "";
    },
    onError: error => setErrors(error.error)
  })

  const onSubmit = async e => {
    e.preventDefault();
    if (file && file.size > 2097152) {
      setErrors({"image": ["Image must be less than 2MB"]})
    } else {
      updateImage({params: {image: file}});
    }
  }

  const validation = errors || {};

  return (
    <form className={formClass} onSubmit={onSubmit}>

      <div className={headingClass}>Profile Picture</div>

      <div className={`bg-gray-200 relative z-10 -mb-4 ${circleClass}`}>
        <label className={`flex absolute top-0 left-0 z-20 transition-all justify-center items-center group cursor-pointer ${imgSrc ? "hover:bg-[#E0E0E040]" : ""} ${circleClass} ${errors ? "border-2 border-flow-red-2 border-opacity-100" : "border border-opacity-20"}`} htmlFor="photo">
          <PlusIcon
            className={`w-20 h-20 transition-opacity ${imgSrc ? "opacity-0 group-hover:opacity-70" : "opacity-50 group-hover:opacity-80"}`}
            fill="#A9A9A9" alt=""
          />
        </label>
        <input
          type="file" className="w-px h-px opacity-0" id="photo" ref={ref}
          accept="image/png, image/gif, image/jpeg"
          onChange={fileChanged}
        />
        {imgSrc && <img src={imgSrc} alt="" className={`absolute top-0 left-0 object-cover ${circleClass}`} />}
      </div>

      {validation.image &&  (
        <div className="mt-6 max-w-xs sm:ml-36 sm:absolute sm:top-28 sm:-mb-12">
          {validation.image.map((error, index) => <div key={index} className="text-xs text-flow-red-2">{error}</div>)}
        </div>
      )}

      <div className="flex gap-2 items-end">
        <Button className={buttonClass} type="submit" loading={loading}>
          Save Photo
        </Button>
        {imgSrc && (
          <Button className="btn-tertiary text-flow-red-1" type="button" onClick={clear}>
            Clear
          </Button>
        )}
      </div>

    </form>
  );
};

UserImageForm.propTypes = {
  formClass: PropTypes.string.isRequired,
  headingClass: PropTypes.string.isRequired,
  buttonClass: PropTypes.string.isRequired,
};

export default UserImageForm;