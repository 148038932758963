import React from "react";
import PropTypes from "prop-types";

const Checkbox = props => {

  const { checked, onChange } = props;

  return (
    <div className="flex gap-1 items-center cursor-pointer" onClick={() => onChange(!checked)}>
      <div className="w-4 h-4 border border-[#656565] p-0.5 rounded flex justify-center items-center">
        <div className={`w-full h-full bg-flow-blue-7 rounded-sm ${checked ? "" : "hidden"}`} />
      </div>
      <label className="cursor-pointer">{props.children}</label>
    </div>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Checkbox;