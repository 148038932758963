import React from "react";
import PropTypes from "prop-types";

const PipelineTags = props => {

  const { pipeline, small } = props;

  const tagClass = `rounded text-white h-full flex items-center whitespace-nowrap text-xs ${small ? "px-1 md:px-2" : "px-2 md:px-3"} sm:text-sm md:text-base`;

  return (
    <div className={`flex ${small ? "gap-1.5 text-sm h-6" : "gap-2 text-base h-8"} ${props.className || ""}`}>
      {pipeline.is_nfcore && <div className={`${tagClass} bg-flow-green-4`}>nf-core</div>}
      {pipeline.prepares_genome && <div className={`${tagClass} bg-pink-500`}>Genome</div>}
    </div>
  );
};

PipelineTags.propTypes = {
  pipeline: PropTypes.object.isRequired,
  small: PropTypes.bool,
};

export default PipelineTags;