import React from "react";
import PropTypes from "prop-types";
import Toggle from "./Toggle";
import { Link } from "react-router-dom";
import { useLazyFetch } from "../hooks";
import { ClipLoader } from "react-spinners";

const PrivacyToggle = props => {

  const { object, setObject } = props;

  const objectType = object.filename ? "data" : object.pipeline_name ? "execution" : "project" in object ? "sample" : "project";
  const pluralType = objectType === "data" ? "data" : `${objectType}s`;

  const [{loading}, updateObject] = useLazyFetch(`/${pluralType}/${object.id}/edit`, {
    method: "POST",
    onCompleted: object => setObject(object)
  });

  const toggleClicked = value => {
    updateObject({params: {private: value}})
  }

  const forcingObject = object.public_project_id ? "project" :
  object.public_sample_id ? "sample" : object.public_execution_id ?
  "execution" : "";

  const forcedPublic = Boolean(
    object.public_project_id || object.public_sample_id || object.public_execution_id
  )

  const fieldMap = {
    scientist: "A scientist.",
    sequencer: "A sequencer.",
    source: "A validated cell/tissue type.",
    purification_target: "A validated protein purification target (category dependent)."
  }

  const missingSamples = objectType === "project" && object.samples.length === 0;
  const forcedPrivate = (object.public_issues?.length > 0 || missingSamples) && object.private && !forcedPublic;
  const missingFields = forcedPrivate ? object.public_issues.map(issue => fieldMap[issue]).filter(Boolean) : [];

  const infoClass = "info mb-2 pl-2 text-sm max-w-md md:text-base md:mb-4";
  const linkClass = "text-flow-blue-7 hover:underline";

  return (
    <div className="edit-section border-t-0 pt-0 mt-0">
      <h2 className="edit-heading">Privacy</h2>
      {forcedPublic && (
        <div className={infoClass}>
          This {objectType} must be <span className="font-semibold">public</span> because
          {object.public_project_id ? (
            <span> its <Link to={`/projects/${object.public_project_id}/`} className={linkClass}>project</Link> is public. </span>
          ) : object.public_sample_id ? (
            <span> its <Link to={`/samples/${object.public_sample_id}/`} className={linkClass}>sample</Link> is public. </span>
          ) : object.public_execution_id && (
            <span> its <Link to={`/executions/${object.public_execution_id}/`} className={linkClass}>execution</Link> is public. </span>
          )}
          If that {forcingObject} is made private, this {objectType} {object.private ? "will be private" : "can be made private too"}.
        </div>
      )}
      {forcedPrivate && (
        <div className={infoClass}>
          This {objectType} is <span className="font-semibold">private</span> and can't be made public because
          {missingSamples ? " it has no samples." : (
            objectType === "project" ? " one or more of its samples lack " : " it lacks "
          )}
          {!missingSamples &&  "certain metadata."}
          {missingSamples ? "" : missingFields.length > 0 ? " For the sample to be public it must have:" : "  Problematic samples are:"}
          <ul className="list-disc list-inside text-sm mt-1">
            {object.public_issues.filter(i => typeof i === "string").map(issue => (
              <li key={issue}>{fieldMap[issue] || issue}</li>
            ))}
            {object.public_issues.filter(i => typeof i !== "string").map(issue => (
              <li key={issue.id}>
                <Link className="text-flow-blue-7" to={`/samples/${issue.id}/`}>{issue.name}</Link>
              </li>
            ))}
          </ul>
        </div> 
      )}
      {!forcedPublic && !forcedPrivate && (
        <div className="flex items-center gap-2">
          <Toggle
            value={object.private} onChange={toggleClicked}
            trueLabel="Private" falseLabel="Public"
            className={loading ? "opacity-60 pointer-events-none" : ""}
          />
          {loading && <ClipLoader size={20} />}
        </div>
      )}
    </div>
  );
};

PrivacyToggle.propTypes = {
  object: PropTypes.object.isRequired,
  setObject: PropTypes.func.isRequired,
};

export default PrivacyToggle;