import { useContext, useState } from "react";
import Base from "./Base";
import Tabs from "../components/Tabs";
import { useFetch, useDocumentTitle, useLazyFetch } from "../hooks";
import DataTable2 from "../components/DataTable2";
import { ClipLoader } from "react-spinners";
import { UserContext } from "../contexts";
import { Link, useParams } from "react-router-dom";

const YourDataPage = () => {

  const { slug } = useParams();

  const [tab, setTab] = useState("Owned");
  const [user,] = useContext(UserContext);
  const [ownedDataPage, setOwnedDataPage] = useState(1);
  const [ownedDataCount, setOwnedDataCount] = useState(null);
  const [ownedData, setOwnedData] = useState(null);
  const [ownedDataFilter, setOwnedDataFilter] = useState(null);
  const [hasOwnedData, setHasOwnedData] = useState(null);
  const [sharedDataPage, setSharedDataPage] = useState(1);
  const [sharedDataCount, setSharedDataCount] = useState(null);
  const [sharedData, setSharedData] = useState(null);
  const [sharedDataFilter, setSharedDataFilter] = useState(null);
  const [hasSharedData, setHasSharedData] = useState(null);
  const pageSize = 50;

  useDocumentTitle("Your Data - Flow");

  const { loading: ownedDataLoading } = useFetch("/data/owned", {
    params: {page: ownedDataPage, count: pageSize, filter: ownedDataFilter, group: slug},
    onCompleted: data => {
      setOwnedDataCount(data.count);
      setOwnedData(data.data);
      if (hasOwnedData === null) setHasOwnedData(!!data.count);
      const maxPossiblePage = Math.ceil(data.count / pageSize);
      if (ownedDataPage > maxPossiblePage) setOwnedDataPage(maxPossiblePage || 1);
    }
  })

  const { loading: sharedDataLoading } = useFetch("/data/shared", {
    params: {page: sharedDataPage, count: pageSize, filter: sharedDataFilter, group: slug},
    onCompleted: data => {
      setSharedDataCount(data.count);
      setSharedData(data.data);
      if (hasSharedData === null) setHasSharedData(!!data.count);
      const maxPossiblePage = Math.ceil(data.count / pageSize);
      if (sharedDataPage > maxPossiblePage) setSharedDataPage(maxPossiblePage || 1);
    }
  })

  const [,postBulkDelete] = useLazyFetch("/data/owned", {
    params: {page: ownedDataPage, count: pageSize, filter: ownedDataFilter},
    onCompleted: data => {
      setOwnedDataCount(data.count);
      setOwnedData(data.data);
    }
  })

  const textClass = "text-sm mb-6 max-w-xl info sm:mb-8";

  const group = user.memberships.find(g => g.slug === slug);

  const ownedLabel = (hasOwnedData === false) ? group ? "This group does not own any data yet." : "You do not own any data yet." : group ? "Data owned by this group." : "Data you own.";
  const sharedLabel = (hasSharedData === false) ? group ? "This group has no shared data yet." : "You do not have any shared data yet." : group ? "Data shared with this group." : "Data shared with you.";
  const showOwnedTable = (hasOwnedData === true);
  const showSharedTable = (hasSharedData === true);

  return (
    <Base>
      <h1>{group ? group.name : "Your"} Data</h1>
      <Tabs labels={["Owned", "Shared"]} selected={tab} setSelected={setTab} />
      {tab === "Owned" && (
        <div>
          <div className={textClass}>
            <span>{ownedLabel}</span>
            {user.memberships.length > 0 && !group && (
              <span>
                {" "}Your group{user.memberships.length > 1 ? "s" : ""} may also own data:{" "}
                {user.memberships.map((g, i) => (
                  <Link key={g.slug} to={`/data/@${g.slug}`} className="text-flow-blue-7">
                    {g.name}
                    {i < user.memberships.length - 1 ? ", " : "."}
                  </Link>
                ))}
              </span>
            )}
          </div>
          {ownedDataLoading && !showOwnedTable && (
            <div className="w-full h-48 flex justify-center items-center">
              <ClipLoader size={100} />
            </div>
          )}
          {showOwnedTable && (
            <DataTable2
              data={ownedData}
              page={ownedDataPage}
              pageSize={pageSize}
              setPage={setOwnedDataPage}
              totalCount={ownedDataCount}
              loading={ownedDataLoading}
              onFilter={s => setOwnedDataFilter(s || null)}
              onBulkDelete={postBulkDelete}
              noMessage="No owned data matches that term." 
            />
          )}
        </div>
      )}
      {tab === "Shared" && (
        <div>
          <div className={textClass}>
            <span>{sharedLabel}</span>
            {user.memberships.length > 0 && !group && (
              <span>
                {" "}Your group{user.memberships.length > 1 ? "s" : ""} may also have data shared with {user.memberships.length > 1 ? "them" : "it"}:{" "}
                {user.memberships.map((g, i) => (
                  <Link key={g.slug} to={`/data/@${g.slug}`} className="text-flow-blue-7">
                    {g.name}
                    {i < user.memberships.length - 1 ? ", " : "."}
                  </Link>
                ))}
              </span>
            )}
          </div>
          {sharedDataLoading && !showSharedTable && (
            <div className="w-full h-48 flex justify-center items-center">
              <ClipLoader size={100} />
            </div>
          )}
          {showSharedTable && (
            <DataTable2
              data={sharedData}
              page={sharedDataPage}
              pageSize={pageSize}
              setPage={setSharedDataPage}
              totalCount={sharedDataCount}
              loading={sharedDataLoading}
              onFilter={s => setSharedDataFilter(s || null)}
              noMessage="No shared data matches that term."
            />
          )}
        </div>
      )}
    </Base>
  );

}

YourDataPage.propTypes = {
  
};

export default YourDataPage;