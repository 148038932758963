import { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "./Button";
import SearchInput from "./SearchInput";

const SearchInputs = props => {

  const { type, loading } = props;

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [projectName, setProjectName] = useState(null);
  const [projectDescription, setProjectDescription] = useState(null);
  const [projectOwner, setProjectOwner] = useState(null);
  
  const [sampleName, setSampleName] = useState(null);
  const [sampleType, setSampleType] = useState(null);
  const [sampleOwner, setSampleOwner] = useState(null);
  
  const [executionName, setExecutionName] = useState(null);
  const [executionStatus, setExecutionStatus] = useState(null);
  const [executionOwner, setExecutionOwner] = useState(null);
  
  const [dataName, setDataName] = useState(null);
  const [dataType, setDataType] = useState(null);
  const [dataSizeGt, setDataSizeGt] = useState(null);
  const [dataSizeLt, setDataSizeLt] = useState(null);
  const [dataProcess, setDataProcess] = useState(null);
  const [dataOwner, setDataOwner] = useState(null);

  const projectNameText = projectName === null ? searchParams.get("name") || "" : projectName;
  const projectDescriptionText = projectDescription === null ? searchParams.get("description") || "" : projectDescription;
  const projectOwnerText = projectOwner === null ? searchParams.get("owner") || "" : projectOwner;
  
  const sampleNameText = sampleName === null ? searchParams.get("name") || "" : sampleName;
  const sampleTypeText = sampleType === null ? searchParams.get("type") || "" : sampleType;
  const sampleOwnerText = sampleOwner === null ? searchParams.get("owner") || "" : sampleOwner;
  
  const executionNameText = executionName === null ? searchParams.get("name") || "" : executionName;
  const executionStatusText = executionStatus === null ? searchParams.get("status") || "" : executionStatus;
  const executionOwnerText = executionOwner === null ? searchParams.get("owner") || "" : executionOwner;

  const dataNameText = dataName === null ? searchParams.get("name") || "" : dataName;
  const dataTypeText = dataType === null ? searchParams.get("type") || "" : dataType;
  const dataSizeGtText = dataSizeGt === null ? searchParams.get("sizeGt") ? parseFloat(searchParams.get("sizeGt")) / 1000000 : "" : dataSizeGt;
  const dataSizeLtText = dataSizeLt === null ? searchParams.get("sizeLt") ? parseFloat(searchParams.get("sizeLt")) / 1000000 : "" : dataSizeLt;
  const dataProcessText = dataProcess === null ? searchParams.get("process") || "" : dataProcess;
  const dataOwnerText = dataOwner === null ? searchParams.get("owner") || "" : dataOwner;

  const updateUrl = () => {
    const params = {};
    if (type === "Projects") {
      if (projectNameText) params.name = projectNameText;
      if (projectDescriptionText) params.description = projectDescriptionText;
      if (projectOwnerText) params.owner = projectOwnerText;
      const searchParams = new URLSearchParams(params);
      navigate(`/search/projects?${searchParams.toString()}`);
    }
    if (type === "Samples") {
      if (sampleNameText) params.name = sampleNameText;
      if (sampleTypeText) params.type = sampleTypeText;
      if (sampleOwnerText) params.owner = sampleOwnerText;
      const searchParams = new URLSearchParams(params);
      navigate(`/search/samples?${searchParams.toString()}`);
    }
    if (type === "Executions") {
      if (executionNameText) params.name = executionNameText;
      if (executionStatusText) params.status = executionStatusText;
      if (executionOwnerText) params.owner = executionOwnerText;
      const searchParams = new URLSearchParams(params);
      navigate(`/search/executions?${searchParams.toString()}`);
    }
    if (type === "Data") {
      if (dataNameText) params.name = dataNameText;
      if (dataTypeText) params.type = dataTypeText;
      if (dataSizeGtText) params.sizeGt = parseFloat(dataSizeGtText) * 1000000;
      if (dataSizeLtText) params.sizeLt = parseFloat(dataSizeLtText) * 1000000;
      if (dataProcessText) params.process = dataProcessText;
      if (dataOwnerText) params.owner = dataOwnerText;
      const searchParams = new URLSearchParams(params);
      navigate(`/search/data?${searchParams.toString()}`);
    }
  }

  const clear = () => {
    setProjectName("");
    setProjectDescription("");
    setProjectOwner("");
    setSampleName("");
    setSampleType("");
    setSampleOwner("");
    setExecutionName("");
    setExecutionStatus("");
    setExecutionOwner("");
    setDataName("");
    setDataType("");
    setDataSizeGt("");
    setDataSizeLt("");
    setDataProcess("");
    setDataOwner("");
  }

  const blockClass = "flex flex-col gap-4";

  const hasValue = (
    projectNameText || projectDescriptionText || projectOwnerText ||
    sampleNameText || sampleTypeText || sampleOwnerText ||
    executionNameText || executionStatusText || executionOwnerText ||
    dataNameText || dataTypeText || dataSizeGtText || dataSizeLtText ||
    dataProcessText || dataOwnerText
  )

  return (
    <div className={props.className || ""}>
      <div className="flex justify-between font-medium mb-4">
        <div className="text-lg text-flow-gray-9">Filter Criteria</div>
        <div
          onClick={clear}
          className={`text-flow-blue-7 text-sm link ${hasValue || "pointer-events-none opacity-50"}`}
        >Clear all filters</div>
      </div>
      {type === "Projects" && (
        <div className={blockClass}>
          <SearchInput label="Name" value={projectNameText} setValue={setProjectName} />
          <SearchInput label="Description" value={projectDescriptionText} setValue={setProjectDescription} />
          <SearchInput label="Owner" value={projectOwnerText} setValue={setProjectOwner} />
        </div>
      )}
      {type === "Samples" && (
        <div className={blockClass}>
          <SearchInput label="Name" value={sampleNameText} setValue={setSampleName} />
          <SearchInput label="Type" value={sampleTypeText} setValue={setSampleType} />
          <SearchInput label="Owner" value={sampleOwnerText} setValue={setSampleOwner} />
        </div>
      )}
      {type === "Executions" && (
        <div className={blockClass}>
          <SearchInput label="Name" value={executionNameText} setValue={setExecutionName} />
          <SearchInput
            label="Status" setValue={setExecutionStatus}
            value={executionStatusText}
            values={[{label: "---", value: ""}, {label: "Pass", value: "OK"}, {label: "Fail", value: "ERR"}]}
          />
          <SearchInput label="Owner" value={executionOwnerText} setValue={setExecutionOwner} />
        </div>
      )}
      {type === "Data" && (
        <div className={blockClass}>
          <SearchInput label="Name" value={dataNameText} setValue={setDataName} />
          <SearchInput label="Type" value={dataTypeText} setValue={setDataType} />
          <div className="grid grid-cols-2 gap-4">
            <SearchInput label="Min Size (MB)" value={dataSizeGtText} setValue={setDataSizeGt} type="number" />
            <SearchInput label="Max Size (MB)" value={dataSizeLtText} setValue={setDataSizeLt} type="number" />
          </div>
          <SearchInput label="Process" value={dataProcessText} setValue={setDataProcess} />
          <SearchInput label="Owner" value={dataOwnerText} setValue={setDataOwner} />
        </div>
      )}
      <Button
        onClick={updateUrl} loading={loading}
        className={`btn-primary mt-8 px-8 ${hasValue || "pointer-events-none opacity-30"}`}
      >
        Search
      </Button>
    </div>
  );
};

SearchInputs.propTypes = {
  type: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default SearchInputs;