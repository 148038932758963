import { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { USERS_ADMIN } from "../queries";
import UserSelector from "./UserSelector";
import { MAKE_ADMIN, MAKE_RUNNER, REMOVE_ADMIN, REMOVE_RUNNER } from "../mutations";
import { parseError } from "../errors";

const UsersAdmin = () => {

  const { data, loading } = useQuery(USERS_ADMIN);

  const [newAdmin, setNewAdmin] = useState(null);
  const [newRunner, setNewRunner] = useState(null);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const [makeAdmin] = useMutation(MAKE_ADMIN, {
    variables: { user: newAdmin?.id, password },
    refetchQueries: [{ query: USERS_ADMIN }],
    awaitRefetchQueries: true,
    onError: e => {
      setError(Object.values(parseError(e).validation)[0][0])
    },
    onCompleted: () => {
      setNewAdmin(null);
      setError("");
      setPassword("");
    }
  });

  const [removeAdmin] = useMutation(REMOVE_ADMIN, {
    variables: { user: newAdmin?.id, password },
    refetchQueries: [{ query: USERS_ADMIN }],
    awaitRefetchQueries: true,
    onError: e => {
      setError(Object.values(parseError(e).validation)[0][0])
    },
    onCompleted: () => {
      setError("");
      setPassword("");
    }
  });

  const [makeRunner] = useMutation(MAKE_RUNNER, {
    variables: { user: newRunner?.id, password },
    refetchQueries: [{ query: USERS_ADMIN }],
    awaitRefetchQueries: true,
    onError: e => {
      setError(Object.values(parseError(e).validation)[0][0])
    },
    onCompleted: () => {
      setNewRunner(null);
      setError("");
      setPassword("");
    }
  });

  const [removeRunner] = useMutation(REMOVE_RUNNER, {
    variables: { user: newRunner?.id, password },
    refetchQueries: [{ query: USERS_ADMIN }],
    awaitRefetchQueries: true,
    onError: e => {
      setError(Object.values(parseError(e).validation)[0][0])
    },
    onCompleted: () => {
      setError("");
      setPassword("");
    }
  })


  if (loading) return null;

  const headingClass = "font-medium text-lg mb-1";

  const selectorClass = "w-40 border-b rounded-none p-0 ml-5 mt-1"

  return (
    <div className="flex flex-wrap gap-10">
      <div>
        <div className={headingClass}>Admins</div>
        <ul className="list-[square] list-inside">
          {data.admins.map(user => (
            <li key={user.id} className="">
              {user.name}
              <span
                className="text-xs text-red-500 ml-1 cursor-pointer"
                onClick={() => removeAdmin({variables: {user: user.id, password}})}
              >
                Remove
              </span>
            </li>
          ))}
            <div className="flex gap-2 items-end">
              <UserSelector
                user={newAdmin}
                setUser={setNewAdmin}
                inputClass={selectorClass}
              />
              {newAdmin && (
                <div
                  onClick={makeAdmin}
                  className="text-xs cursor-pointer text-flow-blue-7 pb-1"
                >
                  Make admin
                </div>
              )}
            </div>
        </ul>
      </div>
      {data.pipelineRunners && (
        <div>
          <div className={headingClass}>Pipeline Runners</div>
          <ul className="list-[square] list-inside">
            {data.pipelineRunners.map(user => (
              <li key={user.id} className="">
                {user.name}
                <span
                  className="text-xs text-red-500 ml-1 cursor-pointer"
                  onClick={() => removeRunner({variables: {user: user.id, password}})}
                >
                  Remove
                </span>
              </li>
            ))}
            <div className="flex gap-2 items-end">
              <UserSelector
                user={newRunner}
                setUser={setNewRunner}
                inputClass={selectorClass}
              />
              {newRunner && (
                <div
                  onClick={makeRunner}
                  className="text-xs cursor-pointer text-flow-blue-7 pb-1"
                >
                  Make pipeline runner
                </div>
              )}
            </div>
          </ul>
        </div>
      )}
      <div>
        <textarea
          value={password}
          onChange={e => setPassword(e.target.value)}
          placeholder="Admin password"
          className="border p-1 resize-none text-sm h-16 w-48"
        />
        {error && <div className="text-red-500 text-xs">{error}</div>}
      </div>
    </div>
  );
};

UsersAdmin.propTypes = {
  
};

export default UsersAdmin;