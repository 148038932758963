import { useState } from "react";
import PropTypes from "prop-types";
import { fileSize } from "../utils";
import { useLazyFetch } from "../hooks";
import DataSelector from "./DataSelector";
import Button from "./Button";
import { ReactComponent as LeftArrow } from "../images/left-arrow.svg";
import { useNavigate } from "react-router-dom";

const FilesetDataEditor = props => {

  const { fileset, setFileset } = props;

  const [data, setData] = useState(null);

  const navigate = useNavigate();

  const [{ loading }, updateFileset] = useLazyFetch(`/filesets/${fileset.id}/edit`, {
    method: "POST",
    onCompleted: fileset => {
      if (!fileset.id) {
        navigate("/");
        return
      }
      setFileset(fileset);
      setData(null);
    }
  });

  return (
    <div className={`${props.className || ""}`}>
      <div className="edit-heading">Data</div>
      <div className="flex flex-col gap-3">
        {fileset.data.map((f, i) => (
          <div key={f.id} className="flex items-center gap-2">
            <div className="text-flow-gray-9 font-medium text-sm">{f.filename}</div>
            <div className="text-flow-blue-7 font-medium text-sm">({fileSize(f.size)})</div>
            <button
              onClick={() => updateFileset({params: {move: f.id, up: true}})}
              className={`${i === 0 ? "pointer-events-none opacity-40" : ""}`}
            >
              <LeftArrow className="w-4 rotate-90 h-auto relative" />
            </button>
            <button
              onClick={() => updateFileset({params: {move: f.id, up: false}})}
              className={` -ml-1 ${i === fileset.data.length - 1 ? "pointer-events-none opacity-40" : ""}`}
            >
              <LeftArrow className="w-4 -rotate-90 h-auto relative top-px" />
            </button>
            <button
              className="text-red-800 text-2xs relative bottom-1 right-0.5"
              onClick={() => updateFileset({params: {remove: f.id}})}
            >
              Remove
            </button>
          </div>
        ))}
      </div>
      <div className="flex mt-3 w-full max-w-lg">
        <DataSelector
          data={data}
          setData={setData}
          isSingle={true}
          className="flex-grow"
          placeholder="Add data to this fileset"
          placeholderClass="text-flow-blue-2 text-opacity-60 text-sm hover:text-opacity-80 md:text-base"
          inputClass="border border-flow-gray-5 outline-none h-9 rounded-l cursor-pointer mb-1.5 py-1.5 text-flow-blue-2 font-medium px-3 text-sm md:text-base w-full max-w-md bg-flow-gray-1 placeholder-text-[#777F9B]"
        />
        <Button
          className={`btn-primary h-full py-1 rounded-l-none ${(loading || !data) ? "pointer-events-none opacity-40" : ""}`}
          loading={loading && data}
          onClick={() => updateFileset({params: {data: data.id}})}
        >
          Add Data
        </Button>
      </div>
    </div>
  );
};

FilesetDataEditor.propTypes = {
  fileset: PropTypes.object.isRequired,
  setFileset: PropTypes.func.isRequired,
};

export default FilesetDataEditor;