import React, { useState } from "react";
import PropTypes from "prop-types";
import Input from "../components/Input";
import Button from "./Button";
import { useLazyFetch } from "../hooks";

const ProjectDetailsForm = props => {

  const { project, setProject } = props;

  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [errors, setErrors] = useState(null);

  const nameText = name === null ? project.name : name;
  const descriptionText = description === null ? project.description : description;

  const [{ loading }, updateProject] = useLazyFetch(`/projects/${project.id}/edit`, {
    method: "POST",
    onCompleted: project => setProject(project),
    onError: error => setErrors(error.error)
  });

  const save = () => {
    setErrors(null);
    updateProject({
      params: {
        name: nameText,
        description: descriptionText
      }
    })
  }

  const labelClass = "text-flow-gray-9 font-medium text-sm block mb-1.5";
  const inputClass = "block bg-flow-gray-2 text-flow-blue-7 rounded text-base px-3 py-1.5 w-full max-w-md sm:text-lg";

  return (
    <div className="edit-section">

      <div className="edit-heading">Details</div>

      <div className="mb-4">
        <label htmlFor="name" className={labelClass}>Project Name</label>
        <Input
          id="name"
          value={nameText}
          className={`${inputClass} text-base font-medium sm:text-lg`}
          onChange={e => setName(e.target.value)}
          required
          errors={errors?.name}
        />
      </div>

      <div>
        <label htmlFor="description" className={labelClass}>Description</label>
        <Input
          id="description"
          value={descriptionText}
          className={`${inputClass} resize-none h-36 text-sm sm:text-base`}
          onChange={e => setDescription(e.target.value)}
          required
          errors={errors?.description}
          textarea={true}
        />
      </div>

      <Button className="btn-primary mt-4" loading={loading} onClick={save}>
        Save Details
      </Button>

    </div>
  );
};

ProjectDetailsForm.propTypes = {
  project: PropTypes.object.isRequired,
  setProject: PropTypes.func.isRequired,
};

export default ProjectDetailsForm;