import React from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import { ReactComponent as UploadIcon } from "../images/upload.svg";

const UploadButton = props => {

  const { files, onClick, cancel } = props;

  const isDisabled = files.length === 0 || cancel;

  return (
    <div className={props.className || ""}>
      <button
        onClick={cancel}
        className={`text-sm block w-fit ml-auto text-flow-blue-7 ${cancel ? "" : "opacity-0 pointer-events-none"}`}
      >
        Cancel
      </button>
      <Button
        className={`btn-primary w-full gap-1.5 ${isDisabled ? "opacity-30 pointer-events-none" : ""}`}
        disabled={isDisabled}
        onClick={onClick}
      >
        <UploadIcon className="relative bottom-px" /> Upload
      </Button>

    </div>
  );
};

UploadButton.propTypes = {
  files: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  cancel: PropTypes.func
};

export default UploadButton;