import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ReactComponent as SampleIcon } from "../images/sample.svg";
import { ReactComponent as TableIcon } from "../images/table.svg";
import CsvInputModal from "./CsvInputModal";

const ExecutionInputs = props => {

  const { execution } = props;

  const [csvPreview, setCsvPreview] = useState(null);

  const propertyClass = "flex flex-col items-baseline sm:table-row";
  const nameClass = "font-medium pr-2 table-cell py-0.5 text-right whitespace-nowrap";
  const valueClass = "text-sm table-cell sm:text-base";

  return (
    <div className="overflow-auto -mr-4 pr-4">
      <div className="flex flex-col gap-1 sm:table">
        {[...Object.entries(execution.csv_params)].map(([name, values]) => {
          return (
            <div key={name} className={propertyClass}>
              <div className={nameClass}>
                --{name}
                <TableIcon
                  className="inline cursor-pointer w-4 ml-1 relative bottom-2 z-10 h-auto fill-flow-blue-1 hover:fill-flow-blue-2"
                  onClick={() => setCsvPreview(name)}
                />
              </div>
              <div className="flex">
                {values.samples.map((sample, index) => {
                  return (
                    <React.Fragment key={sample.id}>
                      {index !== 0 && ", "}
                      <Link className={`${valueClass} text-flow-blue-7 whitespace-nowrap ${index === 0 || "ml-2"}`} to={`/samples/${sample.id}/`}>
                        <SampleIcon className="w-4 -mr-0.5 h-auto inline relative z-10 bottom-0.5" alt="" fill="#3B59C3" /> {sample.name}
                      </Link>
                    </React.Fragment>
                  )
                })}
                {values.data.map((data, index) => {
                  return (
                    <React.Fragment key={data.id}>
                      {index !== 0 && ", "}
                      <Link className={`${valueClass} text-flow-blue-7 whitespace-nowrap ${index === 0 || "ml-2"}`} to={`/data/${data.id}/`}>
                        {data.filename}
                      </Link>
                    </React.Fragment>
                  )
                })}
              </div>
            </div>
          )
        })}
        {[...Object.entries(execution.data_params)].map(([name, value]) => (
          <div key={name} className={propertyClass}>
            <div className={nameClass}>--{name}</div>
            {Array.isArray(value) ? (
              <div className="flex">
                {value.map((d, index) => (
                  <React.Fragment key={d.id}>
                    {index !== 0 && ", "}
                    <Link className={`${valueClass} text-flow-blue-7 ${index === 0 || "ml-2"}`} to={`/data/${d.id}/`}>
                      {d.filename}
                    </Link>
                  </React.Fragment>
                ))}
              </div>
            ) : (
              <Link className={`${valueClass} text-flow-blue-7`} to={`/data/${value.id}/`}>
                {value.filename}
              </Link>
            )}
          </div>
        ))}
        {[...Object.entries(execution.params)].map(([name, value]) => (
          <div key={name} className={propertyClass}>
            <div className={nameClass}>--{name}</div>
            <div className="font-mono text-sm">{value}</div>
          </div>
        ))}
      </div>
      {csvPreview && (
        <CsvInputModal setShow={setCsvPreview} executionId={execution.id} paramName={csvPreview} />
      )}
    </div>
  );
};

ExecutionInputs.propTypes = {
  execution: PropTypes.object.isRequired
};

export default ExecutionInputs;