import React, { useState } from "react";
import PropTypes from "prop-types";
import Input from "../components/Input";
import Button from "./Button";
import { useLazyFetch } from "../hooks";

const FilesetDetailsForm = props => {

  const { fileset, setFileset } = props;

  const [name, setName] = useState(null);
  const [longName, setLongName] = useState(null);
  const [url, setUrl] = useState(null);
  const [errors, setErrors] = useState(null);

  const nameText = name === null ? fileset.name : name;
  const longNameText = longName === null ? fileset.long_name : longName;
  const urlText = url === null ? fileset.url : url;

  const [{ loading }, updateFileset] = useLazyFetch(`/filesets/${fileset.id}/edit`, {
    method: "POST",
    onCompleted: fileset => setFileset(fileset),
    onError: error => setErrors(error.error)
  });

  const save = () => {
    setErrors(null);
    updateFileset({params: {
      name: nameText,
      long_name: longNameText,
      url: urlText
    }});
  }

  const labelClass = "text-flow-gray-9 font-medium text-sm block mb-1.5";
  const inputClass = "block bg-flow-gray-2 text-flow-blue-7 rounded text-base px-3 py-1.5 w-full max-w-md sm:text-lg";

  return (
    <div className="edit-section">

      <div className="edit-heading">Details</div>

      <div className="mb-4">
        <label htmlFor="name" className={labelClass}>Fileset Name</label>
        <Input
          id="name"
          value={nameText}
          className={`${inputClass} text-base font-medium sm:text-lg`}
          onChange={e => setName(e.target.value)}
          required
          errors={errors?.name}
        />
      </div>

      <div className="mb-4">
        <label htmlFor="long_name" className={labelClass}>Long Name</label>
        <Input
          id="long_name"
          value={longNameText}
          className={`${inputClass} text-base font-medium sm:text-lg`}
          onChange={e => setLongName(e.target.value)}
          errors={errors?.long_name}
        />
      </div>

      <div className="mb-4">
        <label htmlFor="url" className={labelClass}>URL</label>
        <Input
          id="url"
          type="url"
          value={urlText}
          className={`${inputClass} text-base font-medium sm:text-lg`}
          onChange={e => setUrl(e.target.value)}
          errors={errors?.url}
        />
      </div>

      <Button className="btn-primary mt-4" loading={loading} onClick={save}>
        Save Details
      </Button>

    </div>
  );
};

FilesetDetailsForm.propTypes = {
  fileset: PropTypes.object.isRequired,
  setFileset: PropTypes.func.isRequired,
};

export default FilesetDetailsForm;