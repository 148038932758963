import { useState, useRef } from "react";
import PropTypes from "prop-types";
import FileInput from "./FileInput";
import UploadButton from "./UploadButton";
import Toggle from "./Toggle";
import SampleSelector from "./SampleSelector";
import MetadataForm from "./MetadataForm";
import { useLazyFetch } from "../hooks";
import { upload } from "../upload";
import MetadataImporter from "./MetadataImporter";

const DemultiplexUploader2 = props => {

  const { projects, organisms, sampleTypes } = props;

  const [single, setSingle] = useState(true);
  const [samples, setSamples] = useState([]);

  const [progress, setProgress] = useState(null);
  const [dataIds, setDataIds] = useState({});
  const [existingSample, setExistingSample] = useState(null);
  const [error, setError] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const canceled = useRef(false);

  const getSampleName = filename => {
    let name = filename;
    if (name.slice(-2) === "gz") name = name.slice(0, -3);
    return name.split(".").slice(0, -1).join(".");
  }

  const samplesWithNames = samples.map(sample => ({
    ...sample,
    name: !sample.name && sample.name !== "" ? getSampleName(sample.file1.name) : sample.name,
    organism: !sample.organism && sample.organism !== "" ? organisms[0].id : sample.organism,
    type_specific_metadata: sample.type_specific_metadata || {},
  }));

  const [,uploadData] = useLazyFetch("/upload/sample", {method: "POST", form: true});

  const [,checkMetadata] = useLazyFetch("/upload/sample/metadata", {
    method: "POST",
  });

  const sendChunk = async (filename, chunk, expectedFileSize, isLast, previous, extra, dataIds) => {
    const matchingSample = samplesWithNames.find(
      sample => sample.file1.name === filename || sample.file2?.name === filename
    );
    const isLastSample = isLast && ((!matchingSample.file2 && matchingSample.file1.name === filename) || matchingSample.file2?.name === filename);
    const previousData = (matchingSample.file2 && matchingSample.file2.name === filename) ? [dataIds[matchingSample.file1.name]] : [];
    let params = {
      blob: chunk, is_last: isLast, expected_file_size: expectedFileSize,
      is_last_sample: isLastSample,
      data: previous?.data?.data_id || "",
      previous_data: previousData.join(","),
      filename,
    }
    if (existingSample && samples.length === 1) {
      params.sample = existingSample.id;
    } else {
      params = {...params, ...matchingSample}
      params.type_specific_metadata = JSON.stringify(params.type_specific_metadata);
      params.sample_name = matchingSample.name;
      delete params.name;
      delete params.file1;
      delete params.file2;
    }
    return await uploadData({params});
  }

  const onNetworkError = () => {
    setError("There was a network error while uploading the data.");
  }

  const onError = error => {
    setError("There was a problem uploading the data.");
  }

  const upload_ = async () => {
    setError("");
    setValidationErrors({});
    setProgress(null);
    setDataIds({});
    const metadataResp = await checkMetadata({params: {metadata: samplesWithNames.map(
      sample => ({...sample, file1: null, file2: null})
    )}});
    if (metadataResp.error?.errors) {
      setValidationErrors(metadataResp.error.errors);
      setError("There was a problem uploading the file - check the metadata below.");
      canceled.current = true;
      setProgress(null);
      return;
    }
    return upload(samplesWithNames, 1_000_000, setProgress, setDataIds, canceled, sendChunk, onNetworkError, onError);
  }

  const cancel = () => {
    setError("");
    setProgress(null);
    setDataIds({});
    setSamples([]);
    canceled.current = true;
  }

  const canCancel = progress && Object.values(progress).length > 0 && !error && Object.values(progress).some(p => p < 1);
  const uploadInProgress = Boolean(progress);

  return (
    <div className="">
      <div className="text-[#515255] text-xl font-medium mb-3">Upload Sample Reads</div>
      <Toggle
        value={single}
        onChange={setSingle}
        labelClass="text-xs sm:text-sm"
        trueLabel="Single-end"
        falseLabel="Paired-end"
        className="mb-4"
      />
      <FileInput
        paired={!single}
        files={samples}
        setFiles={setSamples}
        multiple={true}
        accept=".fq,.fastq,.gz"
        allowDirectories={false}
        progress={progress}
        setProgress={setProgress}
        dataIds={dataIds}
        setDataIds={setDataIds}
        error={error}
        setError={setError}
        className={`mb-3 ${single ? "max-w-2xl" : "max-w-5xl"}`}
      />
      {samples.length <= 1 && (
        <div className="mb-4">
          <label className="text-sm font-medium mb-1">(Optional) Select existing sample to update:</label>
          <SampleSelector
            inputClass="bg-flow-gray-2 rounded mb-1.5 w-full h-9 text-flow-blue-7 font-medium px-3 max-w-2xl"
            sample={existingSample}
            setSample={setExistingSample}
            forceIsOwned={true}
          />
        </div>
      )}
      <UploadButton files={samples} onClick={upload_} cancel={canCancel ? cancel : null} className="max-w-2xl" />
      {!existingSample && samples.length > 0 && (
        <MetadataImporter
          className="mt-16 mb-8"
          samples={samplesWithNames}
          sampleTypes={sampleTypes}
          setSamples={setSamples}
          disabled={uploadInProgress}
        />
      )}
      {!existingSample && samples.length > 0 && (
        <MetadataForm
          metadata={samplesWithNames}
          setMetadata={setSamples}
          projects={projects}
          organisms={organisms}
          sampleTypes={sampleTypes}
          title="Sample Metadata"
          errors={validationErrors}
          disabled={uploadInProgress}
        />
      )}
    </div>
  );
};

DemultiplexUploader2.propTypes = {
  sampleTypes: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  organisms: PropTypes.array.isRequired,
};

export default DemultiplexUploader2;