import { useState } from "react";
import PropTypes from "prop-types";
import OrganismForm from "./OrganismForm";
import DeleteOrganism from "./DeleteOrganism";

const OrganismAdmin = props => {

  const { organism } = props;

  const [editing, setEditing] = useState(false);

  if (editing) return <OrganismForm organism={organism} setShow={setEditing} />;

  return (
    <div>
      <div className="text-2xl font-medium">
        {organism.id}: {organism.name}
        <div
          className="link font-normal relative bottom-4 text-sm inline-block text-flow-blue-7 link hover:underline"
          onClick={() => setEditing(true)}
        >
          Edit
        </div>
        <DeleteOrganism organism={organism} />
      </div>
      <div className="italic">{organism.latinName}</div>
    </div>
  );
};

OrganismAdmin.propTypes = {
  organism: PropTypes.object.isRequired
};

export default OrganismAdmin;